import { FunnelIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import ListItem from './ListItem';
import { ListItemProps } from './ListItem';

export interface ListProps {
  title: string;
  items: ListItemProps[];
  onNewClick: () => void;
  hasFilter: boolean;
}

export default function ReportsList(props: ListProps) {
  const [filter, setFilter] = useState('');

  // Filter based on user input
  const filteredItems: ListItemProps[] = props.items.filter((item) =>
    item.title.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      {props.hasFilter && (
        <div className="mt-6 flex h-11 w-full flex-row items-center justify-start rounded border border-slate-200 bg-slate-50 ">
          <FunnelIcon className="ml-2 h-5 w-5 text-slate-400" />
          <input
            className="ml-2 h-11 w-full bg-transparent focus:outline-none"
            placeholder={`Filter ${props.title.toLowerCase()}`}
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          />
        </div>
      )}
      {/* Map over items */}
      <div className="flex flex-col mt-4">
        {filteredItems
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
          .map((item: ListItemProps) => (
            <ListItem key={item.id} {...item} />
          ))}
      </div>
    </>
  );
}
