import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { useForm } from "react-hook-form";
import {
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "../../firebase/firebase.config";

type FormInputs = {
  email: string;
  password: string;
};

function SignInWithEmailAndPassword() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputs>();
  const { currentUser } = useContext(AuthContext);

  const onSubmit = async (data: FormInputs) => {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (error) {
      console.log(error);
      //   alert('Error signing in. Please try again.');
    }
  };

  const handleSignup = async (data: FormInputs) => {
    try {
      await createUserWithEmailAndPassword(auth, data.email, data.password);
    } catch (error) {
      console.log(error);
      //   alert('Error signing up. Please try again.');
    }
  };

  if (currentUser) {
    return <Navigate to="/" replace />;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="flex flex-col mt-8 w-full">
        <div className=" flex flex-col w-full items-center space-y-4">
          <input
            className="input"
            {...register("email", { required: true })}
            type="email"
            placeholder="Email"
          />
          {errors.email && <span>Email is required</span>}

          <input
            className="input"
            {...register("password", { required: true })}
            type="password"
            placeholder="Password"
          />
          {errors.password && <span>Password is required</span>}
        </div>
        <button
          type="submit"
          className="mt-4 text-lg bg-yellow-500 text-slate-50 w-full py-2 rounded-md"
        >
          Sign in with Email
        </button>

        {/* Sign Up Button */}
        {/* <button
          onClick={() => handleSignup(getValues())} // Use getValues here
          className="mt-4 text-lg bg-blue-500 text-slate-50 px-12 py-2 rounded-md"
        >
          Sign Up with Email
        </button> */}
      </div>
    </form>
  );
}

export default SignInWithEmailAndPassword;
