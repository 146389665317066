import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  return <>{currentUser ? children : <Navigate to="/login" replace />}</>;
};

export default RequireAuth;
