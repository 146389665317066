// External imports
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState, useEffect, useContext } from 'react';
import { setDoc, doc, collection } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

// Internal imports
import { db } from '../../firebase/firebase.config';
import Select, { SelectItemProps } from '../../components/controls/Select';
import Input from '../../components/controls/Input';
import ClosableHeader from '../../components/headers/ClosableHeader';
import { AuthContext } from '../../AuthContext';
import { Report } from '../../db/models';
import useDocumentsByQuery from '../../hooks/useDocumentsByQuery';

interface NewReport {
  name: string;
  tally: SelectItemProps | null;
  productGroup: SelectItemProps | null;
}

const registerOptions = {
  name: { required: 'Provide a name for this report' },
  tally: { required: 'You must select a tally' },
  productGroup: { required: 'You must select a product group' },
};

export default function NewReportPage() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  console.log('status:', isOnline);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewReport>();

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  /**
   * Fetch all tallies and product groups for the current user
   */
  const [tallies, talliesError] = useDocumentsByQuery<SelectItemProps>(
    'tallies',
    'userId',
    currentUser.uid
  );
  const [productGroups, productGroupsError] =
    useDocumentsByQuery<SelectItemProps>(
      'productGroups',
      'userId',
      currentUser.uid
    );

  console.log(tallies);

  // TODO: Handle pontential errors

  /**
   * Create a new report
   */
  const createReport = async (formData: NewReport) => {
    setIsFetching(true);

    navigate('/reports');

    const newReportRef = doc(collection(db, 'reports'));

    const newReportData: Report = {
      id: newReportRef.id,
      name: formData.name,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      userId: currentUser.uid,
      tallyId: formData.tally!.id,
      productGroupId: formData.productGroup!.id,
      status: 'pending',
    };

    await setDoc(newReportRef, { ...newReportData });

    setIsFetching(false);
  };

  return (
    <form
      className="flex w-full h-full flex-col rounded-lg border border-gray-300 m-1 lg:m-0 bg-white p-4"
      onSubmit={handleSubmit(createReport)}
    >
      <ClosableHeader
        title="New report"
        onCloseClick={() => navigate('/reports')}
      />

      {/* Name */}
      <div className=" flex flex-col h-full justify-between mt-6">
        <div className="flex flex-col gap-4">
          {/* Name input */}
          <Input
            label="Report name"
            placeholder="Enter a report name"
            control={control}
            name="name"
            defaultValue=""
            rules={registerOptions.name}
            error={errors?.name}
          />

          {/* Tally select */}
          <div className="flex flex-col gap-1">
            <Controller
              control={control}
              name="tally"
              defaultValue={null}
              rules={registerOptions.tally}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Select a tally"
                  items={tallies}
                  selected={value}
                  onChange={onChange}
                />
              )}
            />
            <p className="ml-auto text-sm text-red-500">
              {errors?.tally && errors.tally.message}
            </p>
          </div>

          {/* Product group select*/}
          <div className="flex flex-col gap-1">
            <Controller
              control={control}
              name="productGroup"
              defaultValue={null}
              rules={registerOptions.productGroup}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Select a product group"
                  items={productGroups}
                  selected={value}
                  onChange={onChange}
                />
              )}
            />
            <p className="ml-auto text-sm text-red-500">
              {errors?.productGroup && errors.productGroup.message}
            </p>
          </div>
        </div>

        {/* Submit button */}
        {!isOnline ? (
          <button
            type="button"
            disabled={!isOnline}
            className="bg-red-500 h-11 text-lg px-4 hover:bg-red-600 cursor-not-allowed rounded w-full text-white"
          >
            Go online to create a new report
          </button>
        ) : (
          <button
            type="submit"
            disabled={!isOnline || isFetching}
            className={`h-11 w-full rounded px-4 py-2 text-lg font-medium text-white  ${
              isFetching ? 'bg-gray-400' : 'bg-yellow-500 hover:bg-yellow-600'
            }`}
          >
            Create report
          </button>
        )}
      </div>
    </form>
  );
}
