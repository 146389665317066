import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";

import { MenuOption } from "../types";
import MenuItem from "./MenuItem";

export default function QuickCreateMenu({ items }: { items: MenuOption[] }) {
  return (
    <div className="flex">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="hidden w-full justify-center rounded border border-slate-200 bg-slate-100 px-2 py-1 text-sm font-medium text-slate-700 hover:bg-slate-200 sm:inline-flex">
            <PlusIcon className="-ml-1 mr-1 h-5 w-5 text-slate-700" />
            Create
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-slate-500"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-1 w-56 origin-top-right rounded-md border border-slate-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
            {items.map((item) => (
              <MenuItem key={item.text} item={item} />
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
