import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import UserMenu from '../menus/UserMenu';

import QuickCreateMenu from '../menus/QuickCreateMenu';
import { NavigationOption } from '../types';
import { MenuOption } from '../types';

import LogoType from '../logo/LogoType';

interface NavButtonProps {
  selected: boolean;
  item: NavigationOption;
}

/**
 * A button for the top navigation menu.
 */
function NavButton({ selected, item }: NavButtonProps) {
  const navigate = useNavigate();
  return (
    <button className="block sm:hidden" onClick={() => navigate(item.route)}>
      {selected ? (
        <item.IconHovered className="h-6 w-6 text-slate-700" />
      ) : (
        <item.Icon className="h-6 w-6 text-slate-700" />
      )}
    </button>
  );
}

interface TopNavProps {
  items: NavigationOption[];
  quickMenuItems: MenuOption[];
  userMenuItems: MenuOption[];
}

/**
 * The top navigation menu.
 */
export default function TopNav({
  items,
  quickMenuItems,
  userMenuItems,
}: TopNavProps) {
  const location = useLocation();

  const tempPathname = location.pathname.split('/')[1] || '';

  return (
    <div className="flex w-full items-center justify-between bg-white px-4 pt-6 pb-2 border-b border-slate-200  sm:h-16 sm:px-4 sm:py-4">
      <LogoType />
      <div className="flex w-full flex-row items-center justify-end gap-6">
        <QuickCreateMenu items={quickMenuItems} />
        {items.map((item) => (
          <NavButton
            key={item.text}
            selected={tempPathname === item.route.split('/')[1]}
            item={item}
          />
        ))}
        <UserMenu items={userMenuItems} />
      </div>
    </div>
  );
}
