// external imports
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// internal imports
import NewProductGroup from './new/NewProductGroup';
import ProductGroupsList from './ProductGroupsList';
import ProductGroup from './ProductGroup';
import NewProduct from './products/new/NewProduct';
import Product from './products/Product';

function ProductGroups() {
  useEffect(() => {
    document.title = 'Product Groups | StumpTally';
  });

  // Tailwind sm breakpoint is 640px
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const isTablet = useMediaQuery({ minWidth: 641, maxWidth: 1024 });

  let display;

  if (isMobile) {
    display = (
      <Routes>
        <Route path="/" element={<ProductGroupsList />} />
        <Route path="new" element={<NewProductGroup />} />
        <Route path=":productGroupId" element={<ProductGroup />} />
        <Route path=":productGroupId/products/new" element={<NewProduct />} />
        <Route
          path=":productGroupId/products/:productId"
          element={<Product />}
        />
      </Routes>
    );
  } else if (isTablet) {
    display = (
      <div className="flex w-full overflow-x-hidden mr-1 overflow-y-hidden">
        <div className="w-1/2 mb-2">
          <Routes>
            <Route path="/" element={<ProductGroupsList />} />
            <Route path=":productGroupId" element={<ProductGroupsList />} />
            <Route
              path=":productGroupId/products/:productId"
              element={<ProductGroup />}
            />
          </Routes>
        </div>
        <div className="w-1/2 mx-1 mb-2">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path="new" element={<NewProductGroup />} />
            <Route path=":productGroupId/*" element={<ProductGroup />} />
            <Route
              path=":productGroupId/products/new"
              element={<NewProduct />}
            />
            <Route
              path=":productGroupId/products/:productId"
              element={<Product />}
            />
          </Routes>
        </div>
      </div>
    );
  } else {
    display = (
      <div className="flex w-full overflow-x-hidden mx-1 my-1 ">
        <div className="lg:w-1/3">
          <ProductGroupsList />
        </div>

        <div className="lg:w-1/3 mx-1">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path="new" element={<NewProductGroup />} />
            <Route path=":productGroupId/*" element={<ProductGroup />} />
          </Routes>
        </div>

        <div className="lg:w-1/3">
          <Routes>
            <Route path="/" element={<div />} />
            <Route
              path=":productGroupId/products/new"
              element={<NewProduct />}
            />
            <Route
              path=":productGroupId/products/:productId"
              element={<Product />}
            />
          </Routes>
        </div>
      </div>
    );
  }

  return display;
}

export default ProductGroups;
