export function createIdMap<T>(items: T[], idKey: string): Record<string, T> {
  return items.reduce((acc: Record<string, T>, item: any) => {
    acc[item[idKey]] = item;
    return acc;
  }, {});
}

export function groupBy<T>(items: T[], key: string): Record<string, T[]> {
  return items.reduce((acc: Record<string, T[]>, item: any) => {
    if (!acc[item[key]]) {
      acc[item[key]] = [];
    }
    acc[item[key]].push(item);
    return acc;
  }, {});
}
