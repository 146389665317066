import { Transition } from '@headlessui/react';
import { ReactNode, Fragment } from 'react';

interface FadeTransitionProps {
  children: ReactNode;
  show?: boolean;
}

export default function FadeTransition({
  children,
  show = true,
}: FadeTransitionProps) {
  return (
    <Transition
      as="span"
      show={show}
      appear={true}
      enter="ease-in duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
}
