// External imports
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import { query, where, collection, onSnapshot } from 'firebase/firestore';

// Internal imports
import { db } from '../../firebase/firebase.config';
import ReportsList from '../../components/lists/ReportsList';
import { ListItemProps } from '../../components/lists/ListItem';
import ItemAddableHeader from '../../components/headers/ItemAddableHeader';
import { getDuration } from '../../utils/time';
import { AuthContext } from '../../AuthContext';
import MobileNewHeader from '../../components/headers/MobileNewHeader';

export default function ReportsListPage() {
  const [reports, setReports] = useState<ListItemProps[]>([]);

  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    document.title = 'Reports | StumpTally';
  });

  useEffect(() => {
    if (!navigate) return;
    if (!currentUser) return;

    const fetchReports = async () => {
      const reportsQuery = query(
        collection(db, 'reports'),
        where('userId', '==', currentUser.uid)
      );

      onSnapshot(reportsQuery, async (snapshot) => {
        const reports = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const reportId = doc.id;
            return {
              id: reportId,
              title: doc.data().name,
              subtitle: `Created ${getDuration(doc.data().createdAt)}`,
              createdAt: doc.data().createdAt,
              updatedAt: doc.data().updatedAt,
              status: doc.data().status,
              onItemClick: () => navigate(`/reports/${reportId}`),
            };
          })
        );
        setReports(reports);
      });
    };

    fetchReports();
  }, [currentUser, navigate]);

  console.log('reports:', reports);

  return (
    <div className="h-full max-h-[50px] min-h-full w-full m-1 lg:m-0">
      <div className=" flex h-full w-full flex-col overflow-y-auto rounded-lg border border-gray-300 bg-white p-4 ">
        <ItemAddableHeader title="Reports" onNewClick={() => navigate('new')} />
        <ReportsList
          title="Reports"
          items={reports}
          onNewClick={() => navigate('new')}
          hasFilter={true}
        />
        {/* mobile new report button */}
        <MobileNewHeader newUrl={'/reports/new'} />
      </div>
    </div>
  );
}
