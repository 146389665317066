import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase.config';
import { useNavigate } from 'react-router';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

interface DeleteFormProps {
  data: { name: string };
  id: string;
  collectionName: string;
  isDeleteFormOpen: boolean;
  setIsDeleteFormOpen: (open: boolean) => void;
  navigateAfterDelete?: string;
}

function DeleteForm({
  data,
  id,
  collectionName,
  isDeleteFormOpen,
  setIsDeleteFormOpen,
  navigateAfterDelete,
}: DeleteFormProps) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const deleteItem = async () => {
    setIsDeleteFormOpen(false);

    // navigate after delete
    if (navigateAfterDelete) {
      navigate(navigateAfterDelete);
    }

    try {
      await deleteDoc(doc(db, collectionName, id));
      console.log('Item deleted successfully');
      setIsDeleteFormOpen(false);
    } catch (error) {
      console.error(`Error deleting ${collectionName.slice(0, -1)}: `, error);
    }
  };

  const deleteButtonName = () => {
    if (collectionName === 'productGroups') {
      return 'Delete product group';
    } else if (collectionName === 'tallies') {
      return 'Delete tally';
    } else if (collectionName === 'reports') {
      return 'Delete report';
    } else if (collectionName === 'products') {
      return 'Delete product';
    } else if (collectionName === 'stumps') {
      return 'Delete stump';
    }
  };

  const deleteHeaderName = () => {
    if (collectionName === 'productGroups') {
      return 'Delete product group';
    } else if (collectionName === 'tallies') {
      return 'Delete tally';
    } else if (collectionName === 'reports') {
      return 'Delete report';
    } else if (collectionName === 'products') {
      return 'Delete product';
    } else if (collectionName === 'stumps') {
      return 'Delete stump';
    }
  };

  const archiveText = () => {
    if (collectionName === 'productGroups') {
      return 'product group';
    } else if (collectionName === 'tallies') {
      return 'tally';
    } else if (collectionName === 'reports') {
      return 'report';
    } else if (collectionName === 'products') {
      return 'product';
    } else if (collectionName === 'stumps') {
      return 'stump';
    }
  };

  return (
    <>
      <Transition appear show={isDeleteFormOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsDeleteFormOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex w-full max-w-md transform items-center justify-center overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form
                    className="flex min-h-[400px] min-w-[370px] flex-col items-start rounded-lg bg-white p-4 "
                    onSubmit={handleSubmit(deleteItem)}
                  >
                    <Dialog.Title
                      as="h3"
                      className="flex  w-full items-center text-2xl font-bold text-slate-700"
                    >
                      {deleteHeaderName()}
                      <XMarkIcon
                        onClick={() => setIsDeleteFormOpen(false)}
                        className="ml-auto flex h-7 w-7 items-center text-black cursor-pointer"
                      />
                    </Dialog.Title>
                    {/* action is permanent section */}
                    <div className="mt-9 flex w-full flex-row items-center justify-center rounded-md bg-red-100">
                      <div className="flex p-5">
                        <div>
                          <ExclamationTriangleIcon className="mr-2 h-6 w-6 text-red-700" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                          <h2 className="text-lg font-semibold text-slate-700">
                            This action is permanent!
                          </h2>
                          <p className="font-normal text-slate-700">
                            You can archive this {archiveText()} to prevent
                            losing it forever.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className=" flex h-full w-full flex-col items-start justify-between ">
                      <div className="outsideDiv mt-9 ">
                        <label className="label" htmlFor="name">
                          Type {archiveText()} name to delete:
                          <span className="text-red-500 font-bold">
                            {' '}
                            {data.name}{' '}
                          </span>
                        </label>
                        <div className="inputDiv">
                          <input
                            className="input"
                            placeholder={data.name}
                            type="text"
                            id="name"
                            {...register('name', {
                              required: 'Must match name',
                              validate: (value) =>
                                value === data.name || 'Must match name',
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    {/* delete button */}
                    <div className="flex w-full flex-row">
                      <button
                        type="submit"
                        disabled={!isValid}
                        className={`mt-20 w-full rounded px-4 py-2 font-medium text-gray-50 disabled:opacity-25 ${
                          isValid ? 'bg-red-500' : 'bg-red-500 hover:bg-red-600'
                        }`}
                      >
                        {deleteButtonName()}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
export default DeleteForm;
