import { useLocation } from "react-router";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase/firestore";

export interface SublistItemProps {
  id: string;
  title: string;
  subtitle?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  onItemClick: () => void;
}

export default function SublistItem(props: SublistItemProps) {
  const location = useLocation();
  return (
    <div
      key={props.id}
      onClick={props.onItemClick}
      className={` cursor-pointer flex flex-row border items-center justify-between rounded-md px-2 py-2 hover:bg-slate-50 ${
        location.pathname.split("/").includes(props.id)
          ? "bg-slate-50 border-slate-100"
          : "border-white"
      }`}
    >
      <div className="flex flex-col ">
        <h3 className="text-left text-base font-medium text-slate-700">
          {props.title}
        </h3>
        <p className="text-left text-sm font-light text-slate-700">
          {props.subtitle}
        </p>
      </div>
      <ChevronRightIcon className="h-5 w-5 text-slate-500" />
    </div>
  );
}
