import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  initializeFirestore,
  memoryLocalCache,
  persistentLocalCache,
  persistentSingleTabManager,
} from 'firebase/firestore';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBQmUqA8W-OySYthPEkNcV55qfyRH9Tim8',
  authDomain: 'stump-tally.firebaseapp.com',
  projectId: 'stump-tally',
  storageBucket: 'stump-tally.appspot.com',
  messagingSenderId: '173417301880',
  appId: '1:173417301880:web:6e4ea0fb317b4582816a8d',
  measurementId: 'G-FVP00G7LHV',
};

const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, { localCache: persistentLocalCache() });

const auth = getAuth();

export {
  db,
  auth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
};
