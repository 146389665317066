import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../AuthContext';
import { Fragment } from 'react';

import { MenuOption } from '../types';
import MenuItem from './MenuItem';

export default function UserMenu({ items }: { items: MenuOption[] }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [hasGeoLocation, setHasGeoLocation] = useState(true);

  // const user = React.useContext(AuthContext).currentUser || {
  //   displayName: "Guest User",
  //   email: "guest@user.com",
  // };

  const defaultUser = { displayName: 'Guest User', email: 'guest@user.com' };
  const firebaseUser = React.useContext(AuthContext).currentUser;
  const user = firebaseUser ? { ...defaultUser, ...firebaseUser } : defaultUser;

  if (user.displayName === null) {
    user.displayName = 'Guest User';
  }

  if (user.email === 'chris@stumptally.com') {
    user.displayName = 'Chris Osborne';
  }

  function checkGeoLocation() {
    if (!navigator.geolocation) {
      setHasGeoLocation(false);
    } else {
      navigator.geolocation.getCurrentPosition(
        () => setHasGeoLocation(true), // Success callback
        (err) => {
          if (err.code === err.PERMISSION_DENIED) {
            setHasGeoLocation(false);
          }
        }
      );
    }
  }

  useEffect(() => {
    checkGeoLocation();

    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <div>
        <Menu.Button className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-slate-600 cursor-pointer">
          <span className="text-xs font-medium text-slate-100">
            {user?.displayName?.charAt(0)}
            {user?.displayName?.split(' ')[1]?.charAt(0)}
          </span>
          {!hasGeoLocation && (
            <>
              <span className="animate-ping absolute top-5 left-5 h-2 w-2 rounded-full bg-red-400 opacity-75"></span>
              <span className="absolute top-5 left-5 rounded-full h-2 w-2 bg-red-500"></span>
            </>
          )}
          {hasGeoLocation && !isOnline && (
            <>
              <span className="animate-ping absolute top-5 left-5 h-2 w-2 rounded-full bg-yellow-400 opacity-75"></span>
              <span className="absolute top-5 left-5 rounded-full h-2 w-2 bg-yellow-500"></span>
            </>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-1 w-56 origin-top-right rounded-md border border-slate-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
          <div className="flex flex-row items-center justify-center py-6 gap-2">
            <div className="inline-flex h-11 w-11 items-center justify-center rounded-full bg-slate-600">
              <span className="text-lg font-medium text-slate-100">
                {user?.displayName?.charAt(0)}
                {user?.displayName?.split(' ')[1]?.charAt(0)}
              </span>
            </div>
            <div className="flex flex-col">
              <p className="text-md font-bold w-36 truncate">
                {user?.displayName}
              </p>
              <p className="text-sm font-light w-36 truncate">{user?.email}</p>
            </div>
          </div>
          {!hasGeoLocation && (
            <div className="flex flex-col bg-red-50 border border-red-500 justify-items-stretch rounded-md mx-2 mb-2 p-2">
              <p className="text-sm">Geolocation disabled!</p>
              <p className="text-xs text-slate-600">
                You must enable geolocation services to map stumps.
              </p>
            </div>
          )}
          {!isOnline && (
            <div className="flex flex-col bg-yellow-50 border border-yellow-500 justify-items-stretch rounded-md mx-2 mb-2 p-2">
              <p className="text-sm">You're currently offline!</p>
              <p className="text-xs text-slate-600">
                Your data is safe and will be synced when you're back online.
              </p>
            </div>
          )}
          {items.map((item) => (
            <MenuItem key={item.text} item={item} />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
