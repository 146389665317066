// external imports
import { useState, useContext, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { useParams, useNavigate } from 'react-router';
// internal imports
import BackNavigableResourceHeader from '../../components/headers/BackNavigableResourceHeader';
import useDocumentsByQuery from '../../hooks/useDocumentsByQuery';
import { Log, Species } from '../../db/models';
import { AuthContext } from '../../AuthContext';
import useAllDocumentsFromCollection from '../../hooks/useAllDocumentsFromCollection';
import useDocumentById from '../../hooks/useDocumentById';

export default function LogPage() {
  const [logName, setLogName] = useState<string | null>('');
  const { currentUser } = useContext(AuthContext);

  const { reportId } = useParams<{ reportId: string }>();

  const { logId } = useParams<{ logId: string }>();
  const navigate = useNavigate();
  const validLogId = logId || '';
  // fetch species
  const [species, speciesError] =
    useAllDocumentsFromCollection<Species>('species');
  console.log(species);
  // fetch all logs - PROB DELETE
  const [logs, logsError] = useDocumentsByQuery<Log>(
    'logs',
    'reportId',
    reportId
  );
  // fetch individual log
  const [log, logError] = useDocumentById<Log>('logs', validLogId);

  console.log('individual log:', log);

  function getCommonNameFromLog(
    log: Log,
    speciesArray: Species[]
  ): string | null {
    const matchingSpecies = speciesArray.find((s) => s.id === log.speciesId);
    return matchingSpecies ? matchingSpecies.commonName : null;
  }

  const commonName = log && species ? getCommonNameFromLog(log, species) : null;

  console.log('commonname:', commonName);

  const titleName = `${commonName} - ${log?.scalingDiameter.toFixed(2)}`;

  const logData = [
    {
      title: 'Value',
      value: `$${log?.revenue.toFixed(2)}`,
    },
    {
      title: 'Species',
      value: commonName,
    },
    {
      title: 'Volume',
      value: `${((log?.grossVolume as number) * 1000).toFixed(2)} BF`,
    },
    {
      title: 'Price ($/MBF)',
      value: `$${((log?.revenue ?? 0) / (log?.grossVolume ?? 1)).toFixed(2)}`,
    },

    {
      title: 'Scaling diameter',
      value: `${log?.scalingDiameter.toFixed(2)}"`,
    },
    {
      title: 'Length',
      value: `${log?.length}'`,
    },
    {
      title: 'Log rule',
      value: log?.logRule,
    },
    {
      title: 'Log position',
      value: log?.position,
    },
  ];

  return (
    <>
      <Transition
        as={Fragment}
        show={true}
        appear={true}
        enter="ease-in duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="h-full max-h-[50px] min-h-full w-full m-1 lg:m-0  ">
          <div className=" flex h-full w-full flex-col overflow-y-auto rounded-lg border border-gray-300 bg-white p-4 ">
            <BackNavigableResourceHeader
              title={titleName}
              onBackClick={() => navigate(`/reports/${reportId}`)}
            />
            <div className="mt-6"></div>
            {logData.map((item) => (
              <Fragment key={item.title}>
                <div className="flex flex-row items-center justify-between py-2 ">
                  <p className="ml-8 text-slate-700">{item.title}</p>
                  <p className=" font-semibold text-slate-700">{item.value}</p>
                </div>
                <hr className="mb-4 w-full border-t border-gray-300"></hr>
              </Fragment>
            ))}
          </div>
        </div>
      </Transition>
    </>
  );
}
