import { useState, useEffect } from "react";
import { fetchAllDocumentsFromCollection, FieldRename } from "../db/fetchers"; // Adjust the path accordingly

function useAllDocumentsFromCollection<T>(
  collectionName: string,
  includeFields?: string[],
  renames?: FieldRename[]
): [T[], Error | null] {
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const fetchedData = await fetchAllDocumentsFromCollection<T>(
          collectionName,
          includeFields,
          renames
        );
        if (isMounted) {
          setData(fetchedData);
        }
      } catch (err) {
        if (isMounted) {
          setError(err as Error);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [collectionName, includeFields, renames]);

  return [data, error];
}

export default useAllDocumentsFromCollection;
