import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import QuickActions from './QuickActions';
import ProductGroupsHome from './ProductGroupsHome';
import TalliesHome from './TalliesHome';
import ReportsHome from './ReportsHome';
import { SpeciesProps } from '../productGroups/products/interfaces';
import {
  collection,
  getDocs,
  doc,
  query,
  where,
  DocumentSnapshot,
} from 'firebase/firestore';
import { db } from '../../firebase/firebase.config';

function Home() {
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser?.uid;
  const [species, setSpecies] = useState<SpeciesProps[]>([]);

  // fetch species from firestore so its cached
  useEffect(() => {
    const fetchFavoritesAndSpecies = async () => {
      // Query reference for user's speciesFavorites
      const favoritesQuery = query(
        collection(db, 'speciesFavorites'),
        where('userId', '==', userId)
      );

      // Fetch user's favorites
      const favoritesSnapshot = await getDocs(favoritesQuery);
      if (!favoritesSnapshot.empty) {
        const docSnap = favoritesSnapshot.docs[0];
        if (docSnap.exists() && docSnap.data()) {
          console.log('Favorites data:', docSnap.data());
          const speciesIds: string[] = docSnap.data().speciesIds;

          // Fetch species data
          const speciesSnapshot = await getDocs(collection(db, 'species'));
          const speciesData = speciesSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })) as SpeciesProps[];

          // Filter for favored species based on the favorites
          const favoredSpecies = speciesData.filter((speciesItem) =>
            speciesIds.includes(speciesItem.id)
          );
        }
      } else {
        console.log('No favorites found for the user');
      }
    };

    // Call the function
    fetchFavoritesAndSpecies();
  }, [userId]);

  // fetch species favorites form firestore

  const user = React.useContext(AuthContext).currentUser || {
    displayName: 'Guest User',
    email: 'guest@user.com',
  };

  // TODO: temporary!!!!
  if (user.email === 'chris@stumptally.com') {
    user.displayName = 'Chris Osborne';
  }

  // grab the first name from displayName
  let firstName = 'Guest';
  if (user.displayName) {
    const fullName = user.displayName.split(' ');
    firstName = fullName[0];
  }

  console.log(user);
  return (
    <div className="mx-2 lg:mx-6 w-full h-full max-h-[50px] min-h-full ">
      <div className="h-full overflow-y-auto  ">
        <h1 className=" text-slate-700 text-2xl font-bold mt-8 lg:ml-2">
          Welcome back, {firstName}!
        </h1>
        {/* Quick actions */}
        <div className="lg:w-auto lg:max-w-fit lg:ml-2">
          <QuickActions />
        </div>
        {/* Product groups home */}
        <div className="lg:w-auto lg:ml-2">
          <ProductGroupsHome />
        </div>
        {/* Tallies home */}
        <div className="lg:w-auto lg:ml-2">
          <TalliesHome />
        </div>
        <div className="lg:w-auto lg:ml-2 mb-4">
          <ReportsHome />
        </div>
      </div>
    </div>
  );
}

export default Home;
