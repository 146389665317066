import React from 'react';

interface TextAreaProps {
  labelText: string;
  id: string;
  registerFunc: any;
  className?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  labelText,
  id,
  registerFunc,
  className,
}) => {
  return (
    <div className={`outsideDiv ${className || ''}`}>
      <label className="label" htmlFor={id}>
        {labelText}
      </label>
      <textarea
        className="textArea mt-2 w-full"
        id={id}
        {...registerFunc(id)}
      />
    </div>
  );
};

export default TextArea;
