// external imports
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
} from 'firebase/firestore';
// internal imports
import { db } from '../../firebase/firebase.config';
import { AuthContext } from '../../AuthContext';
import { ProductGroupPropsWithProductCount } from './interfaces';
import { NewProductGroupProps } from './interfaces';
import ItemAddableHeader from '../../components/headers/ItemAddableHeader';
import FilteredListComponent from '../../components/lists/FilteredList';
import MobileNewHeader from '../../components/headers/MobileNewHeader';
import PageWrapper from '../../components/wrappers/PageWrapper';

function ProductGroupsList() {
  const { currentUser } = useContext(AuthContext);
  const [productGroups, setProductGroups] = useState<
    ProductGroupPropsWithProductCount[]
  >([]);
  const [filteredProductGroups, setFilteredProductGroups] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  // Fetch product groups and products and update in realtime
  useEffect(() => {
    const fetchProductGroups = async () => {
      const userId = currentUser?.uid;

      // Fetch product groups for current user
      const productsGroupQuery = query(
        collection(db, 'productGroups'),
        where('userId', '==', userId)
      );

      onSnapshot(productsGroupQuery, async (snapshot) => {
        const productGroups = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const productGroupId = doc.id;
            const data = doc.data() as NewProductGroupProps;

            // Fetch the product count for the product group
            const productQuerySnapshot = await getDocs(
              query(
                collection(db, 'products'),
                where('productGroupId', '==', productGroupId)
              )
            );
            const productCount = productQuerySnapshot.size;

            return {
              id: productGroupId,
              data: data,
              subtitle: `${productCount} ${
                productCount === 1 ? 'product' : 'products'
              }`,
            };
          })
        );

        setProductGroups(productGroups);
      });
    };

    fetchProductGroups();
  }, []);

  // Filter product groups based on user input
  const productGroupList = productGroups.filter((productGroup) =>
    productGroup.data.name
      .toLowerCase()
      .includes(filteredProductGroups.toLowerCase())
  );

  return (
    <PageWrapper>
      {/* header */}
      <ItemAddableHeader
        title="Product groups"
        onNewClick={() => navigate('new')}
      />

      <FilteredListComponent
        placeholder="Filter product groups"
        onFilterChange={(e) => setFilteredProductGroups(e.target.value)}
        filterValue={filteredProductGroups}
        items={productGroupList.sort(
          (a, b) => b.data.createdAt.seconds - a.data.createdAt.seconds
        )}
        onItemSelect={(productGroup) =>
          navigate(`/productGroups/${productGroup.id}`)
        }
        getIsActivePath={(productGroup) =>
          location.pathname.startsWith(`/productGroups/${productGroup.id}`)
        }
      />
      {/*  mobile new product group button */}
      <MobileNewHeader newUrl={'/productGroups/new'} />
    </PageWrapper>
  );
}

export default ProductGroupsList;
