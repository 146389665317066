type LengthPrice = {
  length: number;
  price: number | null;
};

export const backendToFormFormat = (backendData: {
  [key: string]: number;
}): LengthPrice[] => {
  return Object.entries(backendData).map(([key, value]) => ({
    length: parseInt(key),
    price: value,
  }));
};

// Convert form data to a format suitable for backend submission
export const formToBackendFormat = (
  formData: LengthPrice[]
): { [key: string]: number } => {
  const result: { [key: string]: number } = {};
  formData.forEach((item) => {
    result[item.length] = item.price!;
  });
  return result;
};
