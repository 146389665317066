import { FunnelIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

type FilteredListComponentProps = {
  placeholder: string;
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filterValue: string;
  items: any[];
  onItemSelect: (item: any) => void;
  getIsActivePath: (item: any) => boolean;
};

function FilteredListComponent({
  placeholder,
  onFilterChange,
  filterValue,
  items,
  onItemSelect,
  getIsActivePath,
}: FilteredListComponentProps) {
  console.log(items);
  return (
    <div>
      <div className="mt-6 flex h-11 w-full flex-row items-center justify-start rounded border border-slate-200 bg-slate-50 ">
        <FunnelIcon className="ml-2 h-5 w-5 text-slate-500" />
        <input
          className="ml-2 h-11 w-full bg-transparent focus:outline-none"
          placeholder={placeholder}
          onChange={onFilterChange}
          value={filterValue}
        />
      </div>
      <div className="mt-6">
        {items.map((item) => (
          <div
            key={item.id}
            onClick={() => onItemSelect(item)}
            className={`cursor-pointer mb-3 flex flex-row items-center justify-between rounded-md px-2 hover:bg-slate-50 ${
              getIsActivePath(item) ? 'bg-slate-100' : ''
            }`}
          >
            <div className="flex flex-col">
              <h3 className="text-left text-lg font-semibold text-slate-800">
                {item.data.name}
              </h3>
              <p className="text-left text-sm font-light text-slate-700">
                {item.subtitle}
              </p>
            </div>
            <ChevronRightIcon className="h-5 w-5 text-slate-500" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilteredListComponent;
