import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, FunnelIcon, StarIcon } from '@heroicons/react/24/outline';
import { SpeciesProps } from '../../pages/productGroups/interfaces';
import SortMenu from '../menus/SortMenu';

interface SpeciesDialogProps {
  isFormOpen: boolean;
  setIsFormOpen: (open: boolean) => void;
  allSpecies: SpeciesProps[];
  favoriteSpecies: string[];
  onToggleFavorite: (speciesId: string) => void;
  onSpeciesSelect: (speciesId: string) => void;
}

function SpeciesList({
  isFormOpen,
  setIsFormOpen,
  allSpecies,
  favoriteSpecies,
  onToggleFavorite,
  onSpeciesSelect,
}: SpeciesDialogProps) {
  console.log(allSpecies);
  const [selectedSort, setSelectedSort] = useState('Common name');
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (isFormOpen) {
      const timer = setTimeout(() => {
        setShowInput(true);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setShowInput(false);
    }
  }, [isFormOpen]);

  const [filteredSpecies, setFilteredSpecies] = useState('');

  const sortedSpecies = allSpecies.sort((a, b) => {
    switch (selectedSort) {
      case 'Common name':
        return a.commonName.localeCompare(b.commonName);
      case 'Genus':
        return a.scientificName.localeCompare(b.scientificName);
      case 'FIA species code':
        return a.id.localeCompare(b.id);
      default:
        return 0;
    }
  });

  const filteredSpeciesList = allSpecies.filter((species: SpeciesProps) =>
    species.commonName.toLowerCase().includes(filteredSpecies.toLowerCase())
  );

  return (
    <>
      <Transition appear show={isFormOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsFormOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col w-full max-w-md transform items-center justify-center overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex w-full items-center text-2xl font-semibold text-slate-700"
                  >
                    Select species
                    <XMarkIcon
                      onClick={() => setIsFormOpen(false)}
                      className="ml-auto flex h-7 w-7 items-center text-black cursor-pointer"
                    />
                  </Dialog.Title>
                  {/* filter species */}
                  <div className="flex items-center mt-6 w-full">
                    <div className="flex h-11 w-full flex-row items-center justify-start rounded border border-slate-200 bg-slate-50 ">
                      <FunnelIcon className="ml-2 h-5 w-5 text-slate-500" />
                      <label className="ml-2 text-sm font-medium text-slate-500" />
                      {showInput && (
                        <input
                          className="ml-2 h-full w-full bg-transparent focus:outline-none"
                          placeholder="Filter species"
                          onChange={(e) => setFilteredSpecies(e.target.value)}
                          value={filteredSpecies}
                        />
                      )}
                    </div>
                    {/* sort menu */}
                    <div className="rounded border border-slate-200 bg-slate-50 h-11 items-center flex justify-center ml-2 lg:mr-4">
                      <SortMenu
                        selectedSort={selectedSort}
                        onSelectSort={setSelectedSort}
                      />
                    </div>
                  </div>

                  {/* favorites list */}
                  <div className="w-full lg:h-96 lg:overflow-y-auto">
                    <div className=" mt-8  ">
                      <h4 className="text-slate-700 font-normal text-left">
                        Favorites
                      </h4>
                      <hr className="w-full text-slate-200 mt-2" />
                      {favoriteSpecies.map((speciesId: string) => {
                        const species = allSpecies.find(
                          (s) => s.id === speciesId
                        );
                        if (species) {
                          return (
                            <React.Fragment key={species.id}>
                              <div className="flex justify-between items-center h-12 hover:bg-slate-200 cursor-pointer px-1 rounded-sm">
                                <div
                                  onClick={() => {
                                    onSpeciesSelect(species.id);
                                    setIsFormOpen(false);
                                  }}
                                  className="w-full h-full flex items-center"
                                >
                                  <p className="text-slate-700 font-semibold">
                                    {species.commonName}
                                  </p>
                                </div>

                                <XMarkIcon
                                  onClick={() => onToggleFavorite(species.id)}
                                  className="h-6 w-6 text-slate-500"
                                />
                              </div>
                              <hr className="w-full text-slate-200" />
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </div>

                    {/* species list */}
                    <div className="w-full mt-8  ">
                      <h4 className="text-slate-700 font-normal text-left">
                        Catalog species list
                      </h4>
                      <hr className="w-full text-slate-200 mt-2" />
                      {filteredSpeciesList.map((species: SpeciesProps) => (
                        <React.Fragment key={species.id}>
                          <div className="flex justify-between items-center h-12 hover:bg-slate-200 cursor-pointer px-1 rounded-sm">
                            <div
                              onClick={() => {
                                onSpeciesSelect(species.id);
                                setIsFormOpen(false);
                              }}
                              className="w-full h-full flex items-center"
                            >
                              <p
                                onClick={() => {
                                  onSpeciesSelect(species.id);
                                  setIsFormOpen(false);
                                }}
                                className="text-slate-700 font-semibold"
                              >
                                {species.commonName}
                              </p>
                            </div>
                            <StarIcon
                              onClick={() => onToggleFavorite(species.id)}
                              className={`h-6 w-6 text-slate-500 hover:fill-yellow-500 ${
                                favoriteSpecies.includes(species.id)
                                  ? 'fill-yellow-500'
                                  : ''
                              }  `}
                            />
                          </div>
                          <hr className="w-full text-slate-200" />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default SpeciesList;
