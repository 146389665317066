import { useState, useEffect } from "react";
import { fetchDocumentsByQuery, FieldRename } from "../db/fetchers"; // Adjust the path accordingly

function useDocumentsByQuery<T>(
  collectionName: string,
  fieldName: string,
  fieldValue?: string,
  includeFields?: string[],
  renames?: FieldRename[]
): [T[], Error | null] {
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const fetchedData = await fetchDocumentsByQuery<T>(
          collectionName,
          fieldName,
          fieldValue,
          includeFields,
          renames
        );
        if (isMounted) {
          setData(fetchedData);
        }
      } catch (err) {
        if (isMounted) {
          setError(err as Error);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [collectionName, fieldName, fieldValue, includeFields, renames]);

  return [data, error];
}

export default useDocumentsByQuery;
