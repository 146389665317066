import { PlusCircleIcon } from '@heroicons/react/24/solid';

export interface ItemAddableHeaderProps {
  title: string;
  onNewClick: () => void;
}

export default function ItemAddableHeader(props: ItemAddableHeaderProps) {
  return (
    <div className="flex flex-row items-center justify-between">
      <h1 className="text-2xl font-bold text-slate-700">{props.title}</h1>
      <button className="hidden md:flex" onClick={props.onNewClick}>
        <PlusCircleIcon className="transition ease-in-out duration-300 flex h-10 w-10 fill-yellow-500 hover:scale-125" />
      </button>
    </div>
  );
}
