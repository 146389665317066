// external imports
import { useState, useContext, useTransition } from 'react';
import { Timestamp, collection, doc, setDoc } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
// internal imports
import { db } from '../../../firebase/firebase.config';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../..../../../../AuthContext';
import { NewTallyProps } from '../interfaces';
import Input from '../../../components/controls/Input';
import ClosableHeader from '../../../components/headers/ClosableHeader';

function NewTally() {
  const navigate = useNavigate();

  const [isPending, startTransition] = useTransition();
  const [isFetching, setIsFetching] = useState(false);

  const isMutating = isPending || isFetching;
  const { currentUser } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<NewTallyProps>({
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const createTally = async (formData: NewTallyProps) => {
    setIsFetching(true);

    const userId = currentUser?.uid;

    if (!userId) {
      console.log('No user signed in.');
      setIsFetching(false);
      return;
    }

    const newTally = {
      name: formData.name,
      description: formData.description,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      userId: userId,
      stumpCount: 0,
    };

    // Directly create a new document reference without waiting for `addDoc` promise to resolve
    const newTallyRef = doc(collection(db, 'tallies'));
    const tallyId = newTallyRef.id;

    // Write to cache immediately
    setDoc(newTallyRef, newTally)
      .then(() => {
        // Handle success when online
        console.log('Tally added successfully.');
      })
      .catch((error) => {
        // Handle errors when online
        console.error('Error adding tally: ', error);
      });

    // Navigate immediately after writing to cache
    navigate(`/tallies/${tallyId}`);

    setIsFetching(false);
  };

  const registerOptions = {
    name: { required: 'Provide a name for this tally' },
  };

  const watchAllFields = watch();

  console.log(watchAllFields);

  return (
    <div className="flex h-full w-full m-1 lg:m-0 ">
      {/* form */}
      <form
        className="flex w-full flex-col rounded-lg border border-gray-300 bg-white p-4"
        onSubmit={handleSubmit(createTally)}
      >
        {/* header */}
        <ClosableHeader
          onCloseClick={() => navigate('/tallies')}
          title="New tally"
        />
        {/* <div className="flex flex-row items-center justify-between">
          <h1 className="text-2xl font-bold text-slate-700">New tally</h1>
          <button onClick={() => navigate('/tallies')}>
            <XMarkIcon className="flex h-7 w-7 text-black" />
          </button>
        </div> */}
        {/* name */}
        <div className=" flex flex-col h-full justify-between mt-6">
          <div className="flex flex-col gap-4">
            <Input
              placeholder="Tally name"
              label="Tally name"
              name="name"
              control={control}
              defaultValue=""
              rules={registerOptions.name}
              error={errors?.name}
            />

            <div className="outsideDiv">
              <label className="label" htmlFor="stump-height">
                Notes (optional)
              </label>
              <textarea
                className="textArea mt-2 w-full "
                {...register('description')}
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={isMutating}
          className={`mt-auto h-11 w-full rounded px-4 py-2 text-lg font-medium text-white  ${
            isMutating ? 'bg-gray-400' : 'bg-yellow-500 hover:bg-yellow-600'
          }`}
        >
          Create tally
        </button>
      </form>
    </div>
  );
}

export default NewTally;
