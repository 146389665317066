import { Menu } from '@headlessui/react';
import { MenuOption } from '../types';

export default function MenuItem({ item }: { item: MenuOption }) {
  return (
    <div className="px-1 py-0">
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={item.action}
            className={`${
              active ? 'bg-slate-100' : 'bg-white'
            } group flex w-full items-center rounded-md px-2 py-2 text-sm text-slate-700`}
          >
            {active ? (
              <item.IconHovered
                className="mr-2 h-5 w-5 text-slate-700"
                aria-hidden="true"
              />
            ) : (
              <item.Icon
                className="mr-2 h-5 w-5 text-slate-700"
                aria-hidden="true"
              />
            )}
            {item.text}
          </button>
        )}
      </Menu.Item>
    </div>
  );
}
