import React from 'react';

interface ProductAndStumpListProps {
  title?: string;
  dataPairs: { label?: string | number; value?: string | number | undefined }[];
}

const ProductAndStumpList: React.FC<ProductAndStumpListProps> = ({
  title,
  dataPairs,
}) => {
  return (
    <div className={`flex flex-col `}>
      {title && (
        <>
          <h2 className="mt-6 text-left text-lg font-bold text-slate-700">
            {title}
          </h2>
          <hr className="mt-4 w-full border-t border-gray-300" />
        </>
      )}
      {dataPairs.map((pair, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-row items-center justify-between py-2 ">
            <p className="ml-8 text-slate-700">{pair.label}</p>
            <p className="font-semibold text-slate-700">{pair.value}</p>
          </div>
          <hr className="w-full border-t border-gray-300" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProductAndStumpList;
