import { useContext, useEffect, useState } from 'react';

import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import RequireAuth from './auth/RequireAuth';
import { AuthProvider, AuthContext } from './AuthContext';

// pages
import Home from './pages/home/Home';
import Catalogs from './pages/productGroups/ProductGroups';
import Tallies from './pages/tallies/Tallies';

// layouts
import RootLayout from './layouts/RootLayout';
// components
import Logout from './components/logout/Logout';
import SignInWithGoogle from './components/signin/SignInWithGoogle';
import ReportsLayout from './layouts/ReportsLayout';
// toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from './pages/PageNotFound';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<SignInWithGoogle />} />

      <Route element={<RootLayout />} path="/">
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/productGroups/*"
          element={
            <RequireAuth>
              <Catalogs />
            </RequireAuth>
          }
        />
        <Route
          path="/tallies/*"
          element={
            <RequireAuth>
              <Tallies />
            </RequireAuth>
          }
        />
        <Route
          path="/reports/*"
          element={
            <RequireAuth>
              <ReportsLayout />
            </RequireAuth>
          }
        />

        <Route
          path="/logout"
          element={
            <RequireAuth>
              <Logout />
            </RequireAuth>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </>
  )
);

function showUpdateNotification() {
  toast.info(
    'New update available! Please close and reopen the app to get the latest version.',
    {
      position: 'bottom-right',
      autoClose: false,
    }
  );
}

function App() {
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;

            if (installingWorker) {
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    console.log('Detected a new version, showing the toast.');
                    showUpdateNotification();
                  } else {
                    console.log('Content is now available offline!');
                  }
                }
              };
            }
          };
        })
        .catch((error) => {
          console.error('Service worker registration failed:', error);
        });
    }
    document.title = 'StumpTally';
  }, []);

  return (
    <>
      <AuthProvider>
        <RouterProvider router={router} />
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

export default App;
