export default function PageNotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <h1 className="text-2xl text-slate-800">This page doesn't exist yet.</h1>
      <h2 className="text-lg font-light text-slate-800">
        Don't worry, we're working on it.
      </h2>
    </div>
  );
}
