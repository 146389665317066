// external imports
import { useState, useEffect, useContext } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
} from 'firebase/firestore';
// internal imports
import { db } from '../../firebase/firebase.config';
import { AuthContext } from '../../AuthContext';
import DeleteForm from '../../components/forms/DeleteForm';
import Cards from './Cards';
import { NewTallyProps } from '../tallies/interfaces';
import { TallyProps } from '../tallies/interfaces';
import { getDuration } from '../../utils/time';
import EditForm from '../../components/forms/EditForm';

function TalliesHome() {
  const { currentUser } = useContext(AuthContext);
  const [tallies, setTallies] = useState<TallyProps[]>([]);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedTally, setSelectedTally] = useState<TallyProps | null>();

  const handleDelete = (tally: TallyProps) => {
    setSelectedTally(tally);
    setIsDeleteFormOpen(true);
  };

  const handleEdit = (tally: TallyProps) => {
    setSelectedTally(tally);
    setIsEditFormOpen(true);
  };

  // Fetch tallies and stumps and update in realtime
  useEffect(() => {
    const fetchTallies = async () => {
      const userId = currentUser?.uid;

      // Fetch tallies for current user
      const talliesQuery = query(
        collection(db, 'tallies'),
        where('userId', '==', userId)
      );

      onSnapshot(talliesQuery, async (snapshot) => {
        const tallies = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const tallyId = doc.id;
            const data = doc.data() as NewTallyProps;

            // Fetch the stump count for the tally
            const stumpQuerySnapshot = await getDocs(
              query(collection(db, 'stumps'), where('tallyId', '==', tallyId))
            );
            const stumpCount = stumpQuerySnapshot.size;

            return {
              id: tallyId,
              data: data,
              stumpCount: stumpCount,
            };
          })
        );

        setTallies(tallies);
      });
    };

    fetchTallies();
  }, []);

  console.log('tallies', tallies);

  return (
    <>
      <div className="mt-4 lg:mt-8">
        <div className="flex items-center">
          <CheckCircleIcon className="h-6 w-6 text-slate-500" />
          <h2 className="ml-2 text-slate-700 font-bold text-lg">Tallies</h2>
        </div>
        <div className="lg:flex items-center lg:gap-x-4 lg:gap-y-4 lg:flex-wrap">
          {tallies
            .sort((a, b) => b.data.updatedAt.seconds - a.data.updatedAt.seconds)
            .map((tally) => (
              <span key={tally.id}>
                <Cards
                  name={tally.data.name}
                  id={tally.id as string}
                  collectionName="tallies"
                  childNumber={tally?.stumpCount}
                  childWord={tally.stumpCount === 1 ? ' Stump' : ' Stumps'}
                  updatedAt={getDuration(tally.data.updatedAt)}
                  onDelete={() => handleDelete(tally)}
                  onEdit={() => handleEdit(tally)}
                />
              </span>
            ))}
        </div>
      </div>

      {selectedTally && isEditFormOpen && (
        <EditForm
          data={selectedTally?.data}
          id={selectedTally?.id as string}
          collectionName="tallies"
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
        />
      )}

      {selectedTally && isDeleteFormOpen && (
        <DeleteForm
          data={selectedTally?.data}
          id={selectedTally?.id as string}
          collectionName="tallies"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
        />
      )}
    </>
  );
}

export default TalliesHome;
