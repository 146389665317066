// external imports
import { useState, useContext } from 'react';
import { Timestamp, collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// internal imports
import { db } from '../../../firebase/firebase.config';
import { AuthContext } from '../../../AuthContext';
import { NewProductGroupProps } from '../interfaces';
import ClosableHeader from '../../../components/headers/ClosableHeader';
import Input from '../../../components/controls/Input';

function NewProductGroup() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [isFetching, setIsFetching] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewProductGroupProps>({
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const createProductGroup = async (formData: NewProductGroupProps) => {
    setIsFetching(true);

    const userId = currentUser?.uid;

    if (!userId) {
      console.log('No user signed in.');
      setIsFetching(false);
      return;
    }

    const newProductGroup = {
      name: formData.name,
      description: formData.description,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      userId: userId,
      productCount: 0,
    };

    // Directly create a new document reference without waiting for `addDoc` promise to resolve
    const newProductGroupRef = doc(collection(db, 'productGroups'));
    const productGroupId = newProductGroupRef.id;

    // Write to cache immediately
    setDoc(newProductGroupRef, newProductGroup)
      .then(() => {
        // Handle success when online
        console.log('Product group added successfully.');
      })
      .catch((error) => {
        // Handle errors when online
        console.error('Error adding product group: ', error);
      });

    // Navigate immediately after writing to cache
    navigate(`/productGroups/${productGroupId}`);

    setIsFetching(false);
  };

  const registerOptions = {
    name: { required: 'Provide a name for this product group' },
  };

  return (
    <div className="flex h-full w-full m-1 lg:m-0 ">
      {/* form */}
      <form
        className="flex w-full flex-col rounded-lg border border-gray-300 bg-white p-4"
        onSubmit={handleSubmit(createProductGroup)}
      >
        {/* header */}
        <ClosableHeader
          onCloseClick={() => navigate('/productGroups')}
          title="New product group"
        />
        <div className=" flex flex-col h-full justify-between mt-6">
          <div className="flex flex-col gap-4">
            {/* name */}
            <Input
              placeholder="Product group name"
              label="Product group name"
              name="name"
              control={control}
              defaultValue=""
              rules={registerOptions.name}
              error={errors?.name}
            />

            <div className="outsideDiv">
              <label className="label" htmlFor="stump-height">
                Notes (optional)
              </label>
              <textarea
                className="textArea mt-2 w-full "
                {...register('description')}
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isFetching}
            className={`h-11 w-full rounded px-4 py-2 text-lg font-medium text-white  ${
              isFetching ? 'bg-gray-400' : 'bg-yellow-500 hover:bg-yellow-600'
            }`}
          >
            Create product group
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewProductGroup;
