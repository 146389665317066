// external imports
import { useState, useEffect, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import {
  PencilSquareIcon,
  TrashIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";
import {
  PencilSquareIcon as PencilSquareSolidIcon,
  TrashIcon as TrashSolidIcon,
  PlusCircleIcon,
  DocumentArrowDownIcon as DocumentArrowDownSolidIcon,
} from "@heroicons/react/24/solid";
import { read, utils, writeFile } from "xlsx";
// internal imports
import { MenuOption } from "../../components/types";
import {
  IndividualTallyProps,
  NewStumpProps,
  NewTallyProps,
} from "./interfaces";
import { StumpProps } from "./interfaces";
import { db } from "../../firebase/firebase.config";
import { getDuration } from "../../utils/time";
import FilteredListComponent from "../../components/lists/FilteredList";
import MobileNewHeader from "../../components/headers/MobileNewHeader";
import DeleteForm from "../../components/forms/DeleteForm";
import BackNavigableResourceHeader from "../../components/headers/BackNavigableResourceHeader";
import PageWrapper from "../../components/wrappers/PageWrapper";
import Notes from "../../components/notes/Notes";
import EditForm from "../../components/forms/EditForm";

function Tally() {
  const { tallyId } = useParams<{ tallyId: string }>();
  const [tally, setTally] = useState<IndividualTallyProps>();
  const [stumps, setStumps] = useState<StumpProps[]>([]);
  const [species, setSpecies] = useState<any[]>([]);
  const [filteredStumps, setFilteredStumps] = useState("");
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!tallyId) return;
    const tallyRef = doc(db, "tallies", tallyId);
    const unsubscribe = onSnapshot(tallyRef, (tallyDoc) => {
      if (tallyDoc.exists()) {
        const tallyData: IndividualTallyProps = {
          id: tallyDoc.id,
          data: tallyDoc.data() as NewTallyProps,
        };
        setTally(tallyData);
      } else {
        console.log("Tally not found");
      }
    });

    return () => {
      // Unsubscribe from the snapshot listener when the component unmounts
      unsubscribe();
    };
  }, [tallyId]);

  // Fetch stumps and subscribe to real-time updates
  useEffect(() => {
    const stumpsCollectionRef = collection(db, "stumps");
    const stumpsQuery = query(
      stumpsCollectionRef,
      where("tallyId", "==", tallyId)
    );

    const unsubscribe = onSnapshot(stumpsQuery, (snapshot) => {
      const stumps = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data() as NewStumpProps,
        subtitle: `${doc.data().heightOfDiameter}" stump height`,
      }));
      setStumps(stumps);
    });

    // Clean up the subscription when component unmounts or when params.productGroupId changes
    return () => unsubscribe();
  }, [tallyId]);

  // Fetch the species data from Firestore
  useEffect(() => {
    const fetchSpecies = async () => {
      const speciesRef = collection(db, "species");
      const speciesSnapshot = await getDocs(speciesRef);
      const speciesData = speciesSnapshot.docs.map((doc) => ({
        id: Number(doc.id), // Convert the ID to a number
        speciesCode: doc.data().speciesCode,
        commonName: doc.data().commonName,
      }));

      const matchingSpecies = speciesData.find(
        (species) => species.id === Number(stumps[0]?.data?.speciesList) // Compare the IDs as numbers
      );

      if (matchingSpecies) {
        console.log("Common Name:", matchingSpecies.commonName);
      } else {
        console.log("Species not found");
      }

      setSpecies(speciesData);
    };
    fetchSpecies();
  }, []);

  // filter stump

  const filteredStump = stumps?.filter((stump) => {
    const speciesList = stump?.data?.name.toString().toLowerCase();
    return speciesList.includes(filteredStumps.toLowerCase());
  });

  const exportStumps = useCallback(() => {
    // Helper function to format the timestamp
    const formatTimestamp = (timestamp: Timestamp) => {
      const date = new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      );
      const dateString = date.toLocaleDateString("en-US");
      const timeString = date.toLocaleTimeString("en-US");
      return `${dateString} ${timeString}`;
    };

    // flatten so that the data is in the correct format for the excel file
    const flattenStump = (stump: StumpProps) => {
      const flattened = {
        name: stump?.data?.name,
        "diameter (in)": stump.data.diameter,
        "stumpHeight (in)": stump.data.heightOfDiameter,
        speciesCode: stump.data.speciesList,
        latitude: stump.data.latitude,
        longitude: stump.data.longitude,
        locationAccuracy: stump.data.locationAccuracy,
        createdAt: formatTimestamp(stump.data.createdAt),
        updatedAt: formatTimestamp(stump?.data?.updatedAt as Timestamp),
        id: stump.id,
        tallyId: stump.data.tallyId,
        description: stump.data.description,
      };

      return flattened;
    };

    // Flatten each stump in the array
    const flattenedStumps = stumps.map(flattenStump);

    // Create a new workbook and append the sheet
    const ws = utils.json_to_sheet(flattenedStumps);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Stump data");

    // Write the file
    writeFile(wb, `${tally?.data.name}.xlsx`);
  }, [stumps]);

  const items: MenuOption[] = [
    {
      text: "Edit",
      Icon: PencilSquareIcon,
      IconHovered: PencilSquareSolidIcon,
      action: () => setIsEditFormOpen(true),
    },
    {
      text: "Delete",
      Icon: TrashIcon,
      IconHovered: TrashSolidIcon,
      action: () => setIsDeleteFormOpen(true),
    },
    {
      text: "Export to excel",
      Icon: DocumentArrowDownIcon,
      IconHovered: DocumentArrowDownSolidIcon,
      action: exportStumps,
    },
  ];

  return (
    <>
      <PageWrapper>
        {/* header */}
        <BackNavigableResourceHeader
          title={tally?.data.name as string}
          onBackClick={() => navigate("/tallies")}
          menuItems={items}
        />

        {/* created / updated section */}
        {tally && (
          <div className="mt-4 flex flex-col items-start justify-center font-light text-slate-500">
            <p>Created: {getDuration(tally.data.createdAt)}</p>
            <p>Updated: {getDuration(tally.data.updatedAt)}</p>
          </div>
        )}

        {/* notes section */}
        {tally?.data.description && (
          <Notes description={tally?.data.description} />
        )}

        {/* Stumps section */}
        <div className="mt-8 flex flex-row items-center justify-between">
          <h2 className="text-xl font-bold text-slate-700">Stumps</h2>
          <Link to={`/tallies/${tallyId}/stumps/new`}>
            <PlusCircleIcon className="hidden h-10 w-10 text-yellow-500 md:flex" />
          </Link>
        </div>
        {/* filter stumps */}

        <FilteredListComponent
          filterValue={filteredStumps}
          getIsActivePath={(stump) =>
            location.pathname.startsWith(
              `/tallies/${tallyId}/stumps/${stump.id}`
            )
          }
          items={filteredStump.sort(
            (a, b) => b.data.createdAt.seconds - a.data.createdAt.seconds
          )}
          onFilterChange={(e) => setFilteredStumps(e.target.value)}
          onItemSelect={(stump) =>
            navigate(`/tallies/${tallyId}/stumps/${stump.id}`)
          }
          placeholder="Filter stumps"
        />

        <MobileNewHeader newUrl={`/tallies/${tallyId}/stumps/new`} />
      </PageWrapper>

      {isEditFormOpen && tally && (
        <EditForm
          data={tally?.data}
          id={tally?.id as string}
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
          collectionName="tallies"
        />
      )}
      {isDeleteFormOpen && tally && (
        <DeleteForm
          data={tally?.data}
          id={tally?.id as string}
          collectionName="tallies"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
          navigateAfterDelete={"/tallies"}
        />
      )}
    </>
  );
}

export default Tally;
