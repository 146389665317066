import React from 'react';
import {
  PlusCircleIcon,
  BookOpenIcon,
  CheckCircleIcon,
  DocumentChartBarIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

function QuickActions() {
  const actions = [
    {
      name: 'product group',
      icon: <BookOpenIcon className="h-5 w-5" />,
      link: '/productGroups/new',
    },
    {
      name: 'tally',
      icon: <CheckCircleIcon className="h-5 w-5" />,
      link: '/tallies/new',
    },
    {
      name: 'report',
      icon: <DocumentChartBarIcon className="h-5 w-5" />,
      link: '/reports/new',
    },
  ];
  return (
    <div className="mt-4 lg:mt-8 w-auto ">
      <div className="flex items-center">
        <PlusCircleIcon className="h-6 w-6 text-slate-500" />
        <h2 className="ml-2 text-slate-700 font-bold text-lg">Quick actions</h2>
      </div>
      {/* new product group/tally/report etc */}
      <div className="bg-white flex flex-col lg:flex-row space-y-3 lg:space-y-0 mt-4 p-6 border border-slate-200 lg:space-x-6 self-stretch rounded-md items-center">
        {actions.map((action) => (
          <Link
            to={action.link}
            key={action.name}
            className="action-button w-full lg:w-auto  hover:scale-110 transition ease-in-out duration-200"
          >
            <button className="flex justify-center items-center px-3 py-2 bg-yellow-500 rounded text-white w-full">
              {action.icon}
              <p className="ml-2">Create a new {action.name}</p>
            </button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default QuickActions;
