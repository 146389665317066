import { Controller } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  control: any;
  name: string;
  defaultValue?: any;
  rules?: any;
  error?: {
    message?: string;
  };
}

export default function Input({
  label,
  control,
  name,
  defaultValue,
  rules,
  error,
  ...props
}: InputProps) {
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label className="label text-slate-700" htmlFor={label}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <input
            id={label}
            className="h-10 w-full rounded border border-slate-200 bg-slate-50 pl-1 indent-2"
            {...props}
            {...field}
          />
        )}
      />
      {error?.message && (
        <p className="ml-auto text-sm text-red-500">{error.message}</p>
      )}
    </div>
  );
}
