// external imports
import { useState, useEffect, useContext } from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import {
  collection,
  query,
  onSnapshot,
  doc,
  where,
  getDocs,
} from 'firebase/firestore';
// internal imports
import Cards from './Cards';
import DeleteForm from '../../components/forms/DeleteForm';
import { db } from '../../firebase/firebase.config';
import { AuthContext } from '../../AuthContext';
import { getDuration } from '../../utils/time';
import { ProductGroupPropsWithProductCount } from '../productGroups/interfaces';
import { NewProductGroupProps } from '../productGroups/interfaces';
import EditForm from '../../components/forms/EditForm';

function ProductGroupsHome() {
  const { currentUser } = useContext(AuthContext);
  const [productGroups, setproductGroups] = useState<
    ProductGroupPropsWithProductCount[]
  >([]);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedProductGroup, setSelectedProductGroup] =
    useState<ProductGroupPropsWithProductCount | null>();

  const handleDelete = (productGroup: ProductGroupPropsWithProductCount) => {
    setSelectedProductGroup(productGroup);
    setIsDeleteFormOpen(true);
  };

  const handleEdit = (productGroup: ProductGroupPropsWithProductCount) => {
    setSelectedProductGroup(productGroup);
    setIsEditFormOpen(true);
  };
  // Fetch product groups and products and update in realtime
  useEffect(() => {
    const fetchProductGroups = async () => {
      const userId = currentUser?.uid;

      // Fetch product groups for current user
      const productGroupsQuery = query(
        collection(db, 'productGroups'),
        where('userId', '==', userId)
      );

      onSnapshot(productGroupsQuery, async (snapshot) => {
        const productGroups = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const productGroupId = doc.id;
            const data = doc.data() as NewProductGroupProps;

            // Fetch the product count for the product group
            const productQuerySnapshot = await getDocs(
              query(
                collection(db, 'products'),
                where('productGroupId', '==', productGroupId)
              )
            );
            const productCount = productQuerySnapshot.size;

            return {
              id: productGroupId,
              data: data,
              productCount: productCount,
            };
          })
        );

        setproductGroups(productGroups);
      });
    };

    fetchProductGroups();
  }, []);

  console.log('product groups', productGroups);
  return (
    <>
      <div className="mt-4 lg:mt-8">
        <div className="flex items-center">
          <BookOpenIcon className="h-6 w-6 text-slate-500" />
          <h2 className="ml-2 text-slate-700 font-bold text-lg">
            Product groups
          </h2>
        </div>
        <div className="lg:flex items-center lg:gap-x-4 lg:gap-y-4 lg:flex-wrap">
          {productGroups
            .sort((a, b) => b.data.updatedAt.seconds - a.data.updatedAt.seconds)

            .map((productGroups) => (
              <span key={productGroups.id}>
                <Cards
                  name={productGroups.data.name}
                  id={productGroups.id as string}
                  collectionName="productGroups"
                  childNumber={productGroups?.productCount}
                  childWord={
                    productGroups?.productCount === 1 ? ' Product' : ' Products'
                  }
                  updatedAt={getDuration(productGroups.data.updatedAt)}
                  onEdit={() => handleEdit(productGroups)}
                  onDelete={() => handleDelete(productGroups)}
                />
              </span>
            ))}
        </div>
      </div>

      {selectedProductGroup && isEditFormOpen && (
        <EditForm
          data={selectedProductGroup?.data}
          id={selectedProductGroup?.id as string}
          collectionName="productGroups"
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
        />
      )}

      {selectedProductGroup && isDeleteFormOpen && (
        <DeleteForm
          data={selectedProductGroup?.data}
          id={selectedProductGroup?.id as string}
          collectionName="productGroups"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
        />
      )}
    </>
  );
}

export default ProductGroupsHome;
