import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
} from "../../firebase/firebase.config";
import LogoType from "../logo/LogoType";
import SignInWithEmailAndPassword from "./SignInWithEmailAndPassword";

const SignInWithGoogle: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      alert("Error signing in. Please try again.");
    }
  };

  if (currentUser) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex w-screen h-screen items-center justify-center">
      <div className="flex flex-col text-center items-center justify-center gap-8">
        <img
          src="/stumptally-logo.svg"
          className="h-24 items-center justify-center"
          alt="logo"
        />
        <h1 className="text-lg font-medium">Sign in to StumpTally</h1>
        <button
          className="text-lg bg-yellow-500 text-slate-50 px-12 py-2 rounded-md"
          onClick={loginWithGoogle}
        >
          <div className="flex gap-2 items-center">
            <svg
              className="fill-white"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.001 10.71V7.362h8.424c.126.567.225 1.098.225 1.845 0 5.139-3.447 8.793-8.64 8.793-4.968 0-9-4.032-9-9s4.032-9 9-9c2.43 0 4.464.891 6.021 2.349l-2.556 2.484c-.648-.612-1.782-1.332-3.465-1.332-2.979 0-5.409 2.475-5.409 5.508s2.43 5.508 5.409 5.508c3.447 0 4.716-2.385 4.95-3.798H9.001v-.009z" />
            </svg>
            Continue with Google
          </div>
        </button>
        <SignInWithEmailAndPassword />
      </div>
    </div>
  );
};

export default SignInWithGoogle;
