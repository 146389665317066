import { FC, ReactNode, useRef } from "react";

interface Props {
  children: ReactNode;
  tooltip?: string;
}

const ToolTip: FC<Props> = ({ children, tooltip }): JSX.Element => {
  const tooltipRef = useRef<HTMLSpanElement>(null);

  return (
    <div className="group relative inline-block">
      {children}
      {tooltip ? (
        <span
          ref={tooltipRef}
          className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-slate-700 text-sm text-white py-1 left-11 top-[5.5px] rounded px-2 absolute whitespace-nowrap z-50"
        >
          {tooltip}
        </span>
      ) : null}
    </div>
  );
};

export default ToolTip;
