// external imports
import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, onSnapshot } from 'firebase/firestore';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  PencilSquareIcon as PencilSquareSolidIcon,
  TrashIcon as TrashSolidIcon,
} from '@heroicons/react/24/solid';

// internal imports
import EditProductForm from './EditProductForm';
import { NewProductProps } from './interfaces';
import { getDuration } from '../../../utils/time';
import { SpeciesProps } from './interfaces';
import { ProductProps } from './interfaces';
import { ProductIndividualProps } from './interfaces';
import { MenuOption } from '../../../components/types';
import DeleteForm from '../../../components/forms/DeleteForm';
import BackNavigableResourceHeader from '../../../components/headers/BackNavigableResourceHeader';
import { db } from '../../../firebase/firebase.config';
import PageWrapper from '../../../components/wrappers/PageWrapper';
import ProductAndStumpList from '../../../components/lists/ProductAndStumpList';
import Notes from '../../../components/notes/Notes';

function Product() {
  const { productId, productGroupId } = useParams<{
    productId: string;
    productGroupId: string;
  }>();

  const [product, setProduct] = useState<ProductIndividualProps>();
  const [species, setSpecies] = useState<SpeciesProps[]>([]);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);

  const navigate = useNavigate();

  //  fetch product from firestore
  useEffect(() => {
    if (productId) {
      const productRef = doc(db, 'products', productId);
      const unsubscribe = onSnapshot(productRef, (productDoc) => {
        if (productDoc.exists()) {
          const productData: ProductProps = {
            id: productDoc.id,
            data: productDoc.data() as NewProductProps,
            createdAt: productDoc.data().createdAt,
            updatedAt: productDoc.data().updatedAt,
          };
          setProduct(productData);
        } else {
          console.log('Product not found');
        }
      });

      return () => unsubscribe();
    }
  }, [productId]);

  // Fetch the species data from Firestore
  useEffect(() => {
    const fetchSpecies = async () => {
      const speciesRef = collection(db, 'species');
      const speciesSnapshot = await getDocs(speciesRef);
      const speciesData = speciesSnapshot.docs.map((doc) => ({
        ...(doc.data() as SpeciesProps),
        id: doc.id,
      }));
      setSpecies(speciesData);
    };
    fetchSpecies();
  }, []);

  // Get the matching species based on the speciesList numbers in the product
  const matchingSpecies = species.filter((item) =>
    product?.data?.speciesList?.includes(item.id)
  );

  // Extract the commonNames from the matching species
  const commonNames = matchingSpecies.map((item) => item.commonName);

  const commaSeparatedCommonNames = commonNames.join(', ');

  const items: MenuOption[] = [
    {
      text: 'Edit',
      Icon: PencilSquareIcon,
      IconHovered: PencilSquareSolidIcon,
      action: () => setIsEditFormOpen(true),
    },
    {
      text: 'Delete',
      Icon: TrashIcon,
      IconHovered: TrashSolidIcon,
      action: () => setIsDeleteFormOpen(true),
    },
  ];

  const lengthByPrices =
    product?.data?.lengthPrices &&
    Object.entries(product.data.lengthPrices).map(([key, value]) => {
      return { length: `${key}'`, price: `$${value}` };
    });

  return (
    <>
      <PageWrapper>
        {/* header */}
        <BackNavigableResourceHeader
          title={product?.data.name as string}
          onBackClick={() => navigate(`/productGroups/${productGroupId}`)}
          menuItems={items}
        />

        {/* created / updated section */}
        {product && (
          <div className="mt-4 flex flex-col items-start justify-center font-light text-slate-500">
            <p>Created: {getDuration(product.createdAt)}</p>
            <p>Updated: {getDuration(product.updatedAt)}</p>
          </div>
        )}
        {/* Notes section */}
        {product?.data?.description && (
          <Notes description={product?.data.description} />
        )}

        {/* General section */}
        <ProductAndStumpList
          title="General"
          dataPairs={[
            {
              label: 'Min top-end diameter',
              value: `${product?.data.minimumTopEndDiameter}"`,
            },
            {
              label: 'Units',
              value: product?.data.units,
            },
            {
              label: 'Log rule',
              value: product?.data.logRule,
            },
            {
              label: 'Log position',
              value: product?.data.position,
            },
          ]}
        />
        {/* Species section */}
        <ProductAndStumpList
          title="Species"
          dataPairs={[
            {
              label: commaSeparatedCommonNames,
            },
          ]}
        />
        {/* Prices section */}
        <ProductAndStumpList
          title="Prices"
          dataPairs={
            lengthByPrices?.map((item) => ({
              label: item.length,
              value: item.price,
            })) || []
          }
        />
      </PageWrapper>

      {/* forms */}

      {isEditFormOpen && product && (
        <EditProductForm
          productId={product?.id}
          productGroupId={productGroupId as string}
          initialData={product?.data}
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
        />
      )}

      {isDeleteFormOpen && product && (
        <DeleteForm
          data={product?.data}
          id={product?.id as string}
          collectionName="products"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
          navigateAfterDelete={`/productGroups/${productGroupId}`}
        />
      )}
    </>
  );
}

export default Product;
