// external imports
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
} from 'firebase/firestore';

// internal imports
import { NewTallyProps } from './interfaces';
import { db } from '../../firebase/firebase.config';
import { AuthContext } from '../../AuthContext';
import { TallyProps } from './interfaces';
import ItemAddableHeader from '../../components/headers/ItemAddableHeader';
import FilteredListComponent from '../../components/lists/FilteredList';
import MobileNewHeader from '../../components/headers/MobileNewHeader';
import PageWrapper from '../../components/wrappers/PageWrapper';

function TalliesList() {
  const { currentUser } = useContext(AuthContext);
  const [tallies, setTallies] = useState<TallyProps[]>([]);
  const [filteredTallies, setFilteredTallies] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  // Fetch tallies and stumps and update in realtime
  useEffect(() => {
    const fetchTallies = async () => {
      const userId = currentUser?.uid;

      // Fetch tallies for current user
      const talliesQuery = query(
        collection(db, 'tallies'),
        where('userId', '==', userId)
      );

      onSnapshot(talliesQuery, async (snapshot) => {
        const tallies = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const tallyId = doc.id;
            const data = doc.data() as NewTallyProps;

            // Fetch the stump count for the tally
            const stumpQuerySnapshot = await getDocs(
              query(collection(db, 'stumps'), where('tallyId', '==', tallyId))
            );
            const stumpCount = stumpQuerySnapshot.size;

            return {
              id: tallyId,
              data: data,
              stumpCount: stumpCount,
              subtitle: `${stumpCount} ${
                stumpCount === 1 ? 'stump' : 'stumps'
              }`,
            };
          })
        );

        setTallies(tallies);
      });
    };

    fetchTallies();
  }, []);

  console.log('tallies', tallies);

  // Filter tallies based on user input
  const filteredTallyList = tallies.filter((tally) =>
    tally.data.name.toLowerCase().includes(filteredTallies.toLowerCase())
  );

  return (
    <PageWrapper>
      {/* header */}
      <ItemAddableHeader title="Tallies" onNewClick={() => navigate('new')} />

      <FilteredListComponent
        placeholder="Filter tallies"
        onFilterChange={(e) => setFilteredTallies(e.target.value)}
        filterValue={filteredTallies}
        items={filteredTallyList.sort(
          (a, b) => b.data.createdAt.seconds - a.data.createdAt.seconds
        )}
        onItemSelect={(tally) => navigate(`/tallies/${tally.id}`)}
        getIsActivePath={(tally) =>
          location.pathname.startsWith(`/tallies/${tally.id}`)
        }
      />
      {/* mobile new tally button */}
      <MobileNewHeader newUrl={'/tallies/new'} />
    </PageWrapper>
  );
}

export default TalliesList;
