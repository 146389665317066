import { Timestamp } from 'firebase/firestore';

/**
 * Gets the duration from the given timestamp to the current date.
 *
 * @param {Timestamp} timestamp - The Firestore timestamp to calculate duration from.
 * @returns {string} A string representing the duration since the timestamp.
 * The duration is represented in years, months, or days as appropriate, or "Today" if the timestamp is from the current day.
 *
 * @example
 * // Assume created is a Firestore Timestamp
 * const created: Timestamp = ...
 *
 * const duration = getDuration(created);
 * console.log(duration); // Outputs: "2 months ago", "Today", etc. based on the timestamp
 */
export function getDuration(timestamp: Timestamp) {
  const currentDate = new Date();
  const createdDate = timestamp.toDate();

  // Same Day
  if (
    currentDate.getFullYear() === createdDate.getFullYear() &&
    currentDate.getMonth() === createdDate.getMonth() &&
    currentDate.getDate() === createdDate.getDate()
  ) {
    return 'Today';
  }

  // Calculate differences
  let yearsDiff = currentDate.getFullYear() - createdDate.getFullYear();
  let monthsDiff = currentDate.getMonth() - createdDate.getMonth();
  let daysDiff = currentDate.getDate() - createdDate.getDate();

  // Correct differences
  if (daysDiff < 0) {
    monthsDiff--;
    daysDiff += new Date(
      createdDate.getFullYear(),
      createdDate.getMonth() + 1,
      0
    ).getDate();
  }

  if (monthsDiff < 0) {
    yearsDiff--;
    monthsDiff += 12;
  }

  // Return result
  if (yearsDiff > 0) {
    return `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} ago`;
  } else if (monthsDiff > 0) {
    return `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ago`;
  } else {
    return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
  }
}
