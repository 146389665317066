import { Link } from 'react-router-dom';

export default function LogoType() {
  return (
    <Link to="/">
      <div className="flex flex-row gap-2 items-center">
        <img src="/stumptally-logo.svg" className="w-6 h-6" alt="logo" />
        <h1 className="font-logo text-xl font-bold text-slate-700">
          StumpTally
        </h1>
      </div>
    </Link>
  );
}
