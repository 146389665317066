import { Tab as TabHUI } from "@headlessui/react";

// Helper for tailwinds class name concatenation. Consider moving to utils.
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface TabProps {
  labels: string[];
  content: React.ReactNode[];
}

export default function Tab(props: TabProps) {
  return (
    <TabHUI.Group>
      <TabHUI.List className="flex flex-row justify-between text-slate-700 text-sm mb-4">
        {props.labels.map((label) => (
          <TabHUI
            key={label}
            className={({ selected }) =>
              classNames(
                "border-b-4 w-full py-1",
                selected
                  ? "border-yellow-500 font-semibold"
                  : "border-slate-100 font-normal hover:border-slate-200"
              )
            }
          >
            {label}
          </TabHUI>
        ))}
      </TabHUI.List>
      <TabHUI.Panels>
        {props.content.map((content, idx) => (
          <TabHUI.Panel key={idx}>{content}</TabHUI.Panel>
        ))}
      </TabHUI.Panels>
    </TabHUI.Group>
  );
}
