import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

type MobileNewHeaderProps = {
  newUrl: string;
};

function MobileNewHeader({ newUrl }: MobileNewHeaderProps) {
  return (
    <div className=" absolute bottom-28 right-8">
      <Link to={newUrl}>
        <PlusCircleIcon className="mt-4 ml-auto h-20 w-20 text-yellow-500 md:hidden" />
      </Link>
    </div>
  );
}

export default MobileNewHeader;
