import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { db } from '../../firebase/firebase.config';
import { Timestamp, doc, updateDoc } from 'firebase/firestore';

interface EditFormProps {
  data: {
    name: string;
    description?: string;
  };
  id: string;
  isEditFormOpen: boolean;
  setIsEditFormOpen: (isOpen: boolean) => void;
  collectionName: string;
}

function EditForm({
  data,
  id,
  isEditFormOpen,
  setIsEditFormOpen,
  collectionName,
}: EditFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });

  const editItem = async (formData: any) => {
    setIsEditFormOpen(false);
    try {
      const docRef = doc(db, collectionName, id);
      const updatedData = {
        ...formData,
        updatedAt: Timestamp.now(),
      };
      await updateDoc(docRef, updatedData);
      console.log(`Document updated successfully in ${collectionName}`);
    } catch (error) {
      console.error(`Error updating document in ${collectionName}:`, error);
    }
  };

  const editHeaderName = () => {
    if (collectionName === 'productGroups') {
      return 'Edit product group';
    } else if (collectionName === 'tallies') {
      return 'Edit tally';
    } else if (collectionName === 'reports') {
      return 'Edit report';
    }
  };

  const watchAllFields = watch();

  return (
    <>
      <Transition appear show={isEditFormOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setIsEditFormOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex w-full max-w-md transform items-center justify-center overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form
                    className="flex min-h-[400px] min-w-[370px] flex-col items-start rounded-lg bg-white p-4 "
                    onSubmit={handleSubmit(editItem)}
                  >
                    <Dialog.Title
                      as="h3"
                      className="flex w-full items-center justify-between text-2xl font-bold text-slate-700"
                    >
                      {editHeaderName()}
                      <XMarkIcon
                        className="flex h-7 w-7 text-black"
                        onClick={() => setIsEditFormOpen(false)}
                      />
                    </Dialog.Title>

                    {/* Name */}
                    <div className="outsideDiv">
                      <label className="label" htmlFor="name">
                        Name
                      </label>
                      <input
                        className="input"
                        placeholder="Enter name"
                        type="text"
                        id="name"
                        {...register('name', {
                          required: 'Name is required',
                        })}
                      />
                      {errors.name && (
                        <p className="mr-auto mt-1 text-sm text-red-500">
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    {/* Description */}
                    <div className="outsideDiv">
                      <label className="label" htmlFor="description">
                        Description (optional)
                      </label>
                      <textarea
                        className="textArea mt-2 w-full"
                        {...register('description')}
                      />
                    </div>

                    {/* Submit button */}
                    <button
                      type="submit"
                      className="mt-auto h-11 w-full rounded px-4 py-2 text-lg font-medium text-white bg-yellow-500 hover:bg-yellow-600"
                    >
                      Save edits
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default EditForm;
