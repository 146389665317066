import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationOption } from '../types';

interface NavButtonProps {
  selected: boolean;
  item: NavigationOption;
}

/**
 * A button for the bottom navigation menu.
 */
function NavButton({ selected, item }: NavButtonProps) {
  const navigate = useNavigate();
  return (
    <button
      className="flex flex-col items-center gap-1"
      onClick={() => navigate(item.route)}
    >
      {selected ? (
        <item.IconHovered className="h-6 w-6 text-slate-700" />
      ) : (
        <item.Icon className="h-6 w-6 text-slate-500" />
      )}
      <span className="text-xs text-slate-500">{item.text}</span>
    </button>
  );
}

/**
 * The bottom navigation menu.
 */
export default function BottomNav({ items }: { items: NavigationOption[] }) {
  const location = useLocation();
  const pathname = location.pathname;

  const tempPathname = pathname?.split('/')[1] || '';
  return (
    <div className="sticky mt-2 bottom-0 left-0 z-50 w-full border-t border-slate-300 bg-white pt-2 pb-10 drop-shadow-md sm:hidden">
      <div className="mx-auto grid h-full w-80 grid-cols-4 font-medium">
        {items.map((item) => (
          <NavButton
            key={item.text}
            selected={tempPathname === item.route.split('/')[1]}
            item={item}
          />
        ))}
      </div>
    </div>
  );
}
