import { useLocation } from 'react-router';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Timestamp } from 'firebase/firestore';
import ClipLoader from 'react-spinners/ClipLoader';

export interface ListItemProps {
  id: string;
  title: string;
  subtitle?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status?: 'pending' | 'complete';
  onItemClick: () => void;
}

export default function ListItem(props: ListItemProps) {
  const location = useLocation();

  let iconOrSpinner;
  if (props.status === 'pending') {
    iconOrSpinner = <ClipLoader size={20} color={'#EAB308'} loading={true} />;
  } else {
    iconOrSpinner = <ChevronRightIcon className="h-5 w-5 text-slate-500" />;
  }

  const isPending = props.status === 'pending';

  return (
    <div
      key={props.id}
      onClick={isPending ? undefined : props.onItemClick}
      className={`cursor-pointer flex flex-row border items-center justify-between rounded-md px-2 py-2 hover:bg-slate-50 ${
        location.pathname.split('/').includes(props.id)
          ? 'bg-slate-50 border-slate-100'
          : 'border-white'
      } ${isPending ? 'pointer-events-none' : ''}`}
    >
      <div className="flex flex-col ">
        <h3 className="text-left text-lg font-semibold text-slate-700">
          {props.title}
        </h3>
        <p className="text-left text-sm font-light text-slate-700">
          {props.subtitle}
        </p>
      </div>

      {iconOrSpinner}
    </div>
  );
}
