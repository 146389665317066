import { Listbox } from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/24/outline";
import FadeTransition from "../transitions/FadeTransition";

export interface SelectItemProps {
  id: string;
  name: string;
}

export interface SelectProps {
  label: string;
  items: SelectItemProps[];
  selected: SelectItemProps | null;
  onChange: (item: SelectItemProps | null) => void;
}

export default function Select(props: SelectProps) {
  return (
    <div className="flex flex-col w-full">
      {/* Not sure I'm setting the label correctly for accessibility? */}
      <label className="label text-slate-700" htmlFor={props.label}>
        {props.label}
      </label>
      <div className="flex w-full flex-row items-center">
        <Listbox value={props.selected} onChange={props.onChange}>
          {({ open }) => (
            <div className="relative mt-1 w-full">
              <Listbox.Button className="relative h-10 w-full cursor-default rounded-lg border border-slate-200 bg-slate-50 py-2 pl-3 pr-10 text-left shadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate">
                  {props.selected ? props.selected.name : ""}
                </span>

                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-slate-900"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <FadeTransition show={open}>
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm border border-slate-200">
                  {props.items.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 text-slate-700 ${
                          active && "bg-slate-100"
                        }`
                      }
                      value={item}
                    >
                      <>
                        <span
                          className={`block truncate ${
                            props.selected?.id === item.id
                              ? "font-medium"
                              : "font-normal"
                          }`}
                        >
                          {item.name}
                        </span>
                        {props.selected?.id === item.id ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-yellow-500">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </FadeTransition>
            </div>
          )}
        </Listbox>
      </div>
    </div>
  );
}
