import { Outlet } from 'react-router-dom';
import TopNav from '../components/navigation/TopNav';
import SideNav from '../components/navigation/SideNav';
import BottomNav from '../components/navigation/BottomNav';
import { NavigationOption } from '../components/types';
import { MenuOption } from '../components/types';
import { useNavigate } from 'react-router';

import {
  BookOpenIcon,
  CheckCircleIcon,
  Cog6ToothIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';

import {
  HomeIcon as HomeIconSelected,
  BookOpenIcon as BookOpenIconSelected,
  CheckCircleIcon as CheckCircleIconSelected,
  DocumentChartBarIcon as DocumentChartBarIconSelected,
  Cog6ToothIcon as Cog6ToothIconSelected,
  QuestionMarkCircleIcon as QuestionMarkCircleIconSelected,
  ArrowRightCircleIcon as ArrowRightCircleIconSelected,
} from '@heroicons/react/24/solid';

export default function RootLayout() {
  const navigate = useNavigate();

  const primaryNavItems: NavigationOption[] = [
    {
      Icon: HomeIcon,
      IconHovered: HomeIconSelected,
      text: 'Home',
      route: '/',
    },
    {
      Icon: BookOpenIcon,
      IconHovered: BookOpenIconSelected,
      text: 'Products',
      route: '/productGroups',
    },
    {
      Icon: CheckCircleIcon,
      IconHovered: CheckCircleIconSelected,
      text: 'Tallies',
      route: '/tallies',
    },
    {
      Icon: DocumentChartBarIcon,
      IconHovered: DocumentChartBarIconSelected,
      text: 'Reports',
      route: '/reports',
    },
  ];

  const secondaryNavItems: NavigationOption[] = [
    {
      Icon: Cog6ToothIcon,
      IconHovered: Cog6ToothIconSelected,
      text: 'Settings',
      route: '/settings',
    },
    {
      Icon: QuestionMarkCircleIcon,
      IconHovered: QuestionMarkCircleIconSelected,
      text: 'Help',
      route: '/help',
    },
  ];

  const quickCreateMenuItems: MenuOption[] = [
    {
      Icon: BookOpenIcon,
      IconHovered: BookOpenIconSelected,
      text: 'New Product Group',
      action: () => navigate('/productGroups/new'),
    },

    {
      Icon: CheckCircleIcon,
      IconHovered: CheckCircleIconSelected,
      text: 'New tally',
      action: () => navigate('/tallies/new'),
    },
    {
      Icon: DocumentChartBarIcon,
      IconHovered: DocumentChartBarIconSelected,
      text: 'New report',
      action: () => navigate('/reports/new'),
    },
  ];

  const userMenuItems: MenuOption[] = [
    {
      Icon: Cog6ToothIcon,
      IconHovered: Cog6ToothIconSelected,
      text: 'Settings',
      action: () => navigate('/settings'),
    },
    {
      Icon: ArrowRightCircleIcon,
      IconHovered: ArrowRightCircleIconSelected,
      text: 'Logout',
      action: () => navigate('/logout'),
    },
  ];

  return (
    <div className="flex h-[100dvh] w-[100dvw] max-w-[100dvw] flex-col bg-slate-50">
      <TopNav
        items={secondaryNavItems}
        quickMenuItems={quickCreateMenuItems}
        userMenuItems={userMenuItems}
      />
      <div className="flex h-full w-full flex-row">
        <SideNav topItems={primaryNavItems} bottomItems={secondaryNavItems} />

        <div className="flex h-full w-full ">
          <Outlet />
        </div>
      </div>
      <BottomNav items={primaryNavItems} />
    </div>
  );
}
