import { useState, useEffect } from 'react';
import { fetchDocumentById, FieldRename } from '../db/fetchers'; // Adjust the path accordingly

function useDocumentById<T>(
  collectionName: string,
  docId: string,
  includeFields?: string[],
  renames?: FieldRename[]
): [T | null, Error | null] {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const fetchedData = await fetchDocumentById<T>(
          collectionName,
          docId,
          includeFields,
          renames
        );
        if (isMounted) {
          setData(fetchedData);
        }
      } catch (err) {
        if (isMounted) {
          setError(err as Error);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [collectionName, docId, includeFields, renames]);

  return [data, error];
}

export default useDocumentById;
