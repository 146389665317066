// External imports
import { useState, useEffect, useCallback } from 'react';
import { read, utils, writeFile } from 'xlsx';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
  TrashIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline';
import {
  PencilSquareIcon as PencilSquareSolidIcon,
  TrashIcon as TrashSolidIcon,
  DocumentArrowDownIcon as DocumentArrowDownSolidIcon,
} from '@heroicons/react/24/solid';
import { Disclosure } from '@headlessui/react';

// Internal imports
import { buildExcelData } from '../../exports/buildExcelData';
import BackNavigableResourceHeader from '../../components/headers/BackNavigableResourceHeader';
import { Report, Species, Log, Product, Stump } from '../../db/models';
import { getDuration } from '../../utils/time';
import {
  fetchAllDocumentsFromCollection,
  fetchDocumentById,
  fetchDocumentsByQuery,
} from '../../db/fetchers';
import { createIdMap, groupBy } from '../../db/transforms';
import Tab from '../../components/controls/Tab';
import SublistItem from '../../components/lists/SublistItem';
import EditForm from '../../components/forms/EditForm';
import DeleteForm from '../../components/forms/DeleteForm';

type DataGroup = {
  idMap: Record<string, any>;
  items: Record<string, any[]>;
};

export default function ReportPage() {
  const [data, setData] = useState<Record<string, DataGroup>>({});
  const [revenueSums, setRevenueSums] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<Report | null>(null);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  // Extract the report id from the URL
  const { reportId } = useParams<{ reportId: string }>();
  const validReportId = reportId || '';
  const navigate = useNavigate();

  console.log('reportData', data);

  // This effect fetches the report and then all the logs, products, species, and stumps in parallel.
  // It then groups the logs by product, species, and stump and stores them in the data state and
  // computes the revenue sums for each group.
  useEffect(() => {
    async function fetchData() {
      try {
        const report = await fetchDocumentById<Report>(
          'reports',
          validReportId
        );

        if (!report) throw new Error('Report not found');
        setReport(report);

        const [species, logs, products, stumps] = await Promise.all([
          fetchAllDocumentsFromCollection<Species>('species'),
          fetchDocumentsByQuery<Log>('logs', 'reportId', validReportId),
          fetchDocumentsByQuery<Product>(
            'products',
            'productGroupId',
            report.productGroupId
          ),
          fetchDocumentsByQuery<Stump>('stumps', 'tallyId', report.tallyId),
        ]);

        const data: Record<string, DataGroup> = {
          'By Product': {
            idMap: createIdMap(products, 'id'),
            items: groupBy(logs, 'productId'),
          },
          'By Species': {
            idMap: createIdMap(species, 'id'),
            items: groupBy(logs, 'speciesId'),
          },
          'By Stump': {
            idMap: createIdMap(stumps, 'id'),
            items: groupBy(logs, 'stumpId'),
          },
        };
        // Create an object for the sum of all the log's revenue for each product id, species id, and stump id.
        const revenueSums: Record<string, number> = {};
        Object.keys(data).forEach((key) => {
          Object.keys(data[key].items).forEach((id) => {
            const sum = data[key].items[id].reduce(
              (acc: number, log: Log) => acc + log.revenue,
              0
            );
            revenueSums[id] = sum;
          });
        });

        // Add a key to the revenueSums object called "total" that is the sum of all the revenueSums
        revenueSums['total'] = Object.values(revenueSums).reduce(
          (acc: number, sum: number) => acc + sum,
          0
        );
        revenueSums['total'] /= 3;

        setData(data);
        setRevenueSums(revenueSums);
      } catch (error) {
        console.error(error);
        navigate('/reports');
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [validReportId]);

  const exportFile = useCallback(() => {
    const wb = utils.book_new();

    for (const key in data) {
      const flattenedData = buildExcelData(data[key], revenueSums);
      const ws = utils.json_to_sheet(flattenedData);
      utils.book_append_sheet(wb, ws, key);
    }

    writeFile(wb, `${report?.name}.xlsx`);
  }, [data, revenueSums]);

  const menuItems = [
    {
      text: 'Edit',
      Icon: PencilSquareIcon,
      IconHovered: PencilSquareSolidIcon,
      action: () => {
        setIsEditFormOpen(true);
      },
    },
    {
      text: 'Delete',
      Icon: TrashIcon,
      IconHovered: TrashSolidIcon,
      action: () => {
        setIsDeleteFormOpen(true);
      },
    },
    {
      text: 'Export to excel',
      Icon: DocumentArrowDownIcon,
      IconHovered: DocumentArrowDownSolidIcon,
      action: exportFile,
    },
  ];

  if (isLoading || !report) return null;

  return (
    <>
      <div className="h-full max-h-[50px] min-h-full w-full m-1 lg:m-0  ">
        <div className=" flex h-full w-full flex-col overflow-y-auto rounded-lg border border-gray-300 bg-white p-4 ">
          {/* header */}
          <BackNavigableResourceHeader
            title={report.name}
            onBackClick={() => navigate('/reports')}
            menuItems={menuItems}
          />
          <div className="flex flex-col py-2 text-sm text-slate-500 mt-2">
            <div>Created {getDuration(report.createdAt)}</div>
            <div>Updated {getDuration(report.updatedAt)}</div>
          </div>
          <div className="text-slate-700 font-bold text-xl mt-4 mb-2">
            {`Total value: $${(
              Math.round((revenueSums.total + Number.EPSILON) * 100) / 100
            ).toLocaleString()}`}
          </div>

          {/* Map over items */}
          <div className="mt-6">
            <Tab
              labels={Object.keys(data)}
              content={Object.values(data).map((group, idx) => (
                <div key={idx}>
                  {Object.keys(group.items).map((key) => (
                    // Consider moving Disclosure stuff to a GroupedList component
                    <Disclosure key={key}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={`flex flex-row items-center justify-between w-full rounded-md hover:bg-slate-50 px-2 py-1`}
                          >
                            <div className="flex flex-col items-start">
                              <div className="font-bold text-base text-slate-700">
                                {group.idMap[key].name ||
                                  group.idMap[key].commonName}
                              </div>
                              <div className="text-sm font-light text-slate-500">
                                {`${group.items[key].length} logs - $${
                                  Math.round(
                                    (revenueSums[key] + Number.EPSILON) * 100
                                  ) / 100
                                } value`}
                              </div>
                            </div>
                            {open ? (
                              <MinusIcon className="w-5 stroke-slate-500" />
                            ) : (
                              <PlusIcon className="w-5 stroke-slate-500" />
                            )}
                          </Disclosure.Button>
                          <Disclosure.Panel className="border-l border-slate-200 pl-2 ml-3">
                            {group.items[key]
                              .sort((a: any, b: any) => b.position - a.position)
                              .map((log: any) => (
                                <SublistItem
                                  id={log.id}
                                  key={log.id}
                                  title={`${
                                    data['By Species'].idMap[log.speciesId]
                                      .commonName
                                  } - ${
                                    Math.round(
                                      (log.scalingDiameter + Number.EPSILON) *
                                        100
                                    ) / 100
                                  }"`}
                                  subtitle={`$${
                                    Math.round(
                                      (log.revenue + Number.EPSILON) * 100
                                    ) / 100
                                  } value`}
                                  createdAt={log.createdAt}
                                  updatedAt={log.updatedAt}
                                  onItemClick={() =>
                                    navigate(
                                      `/reports/${reportId}/logs/${log.id}`
                                    )
                                  }
                                />
                              ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              ))}
            />
          </div>
        </div>
      </div>
      {isEditFormOpen && (
        <EditForm
          data={report}
          id={report.id as string}
          collectionName="reports"
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
        />
      )}

      {isDeleteFormOpen && (
        <DeleteForm
          data={report}
          id={report.id as string}
          collectionName="reports"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
          navigateAfterDelete="/reports"
        />
      )}
    </>
  );
}
