export function buildExcelData(
  dataGroup: any,
  revenueSums: Record<string, number>
): any[] {
  const flattened: any[] = [];

  // Variables to hold totals
  let totalRevenue = 0;
  let totalGrossVolume = 0;
  let totalLength = 0;
  let totalNetVolume = 0;

  for (const id in dataGroup.items) {
    const items = dataGroup.items[id];

    // Loop over each individual item
    for (const singleItem of items) {
      const revenue = `$ ${(singleItem.revenue || revenueSums[id]).toFixed(2)}`;
      const grossVolume = (singleItem.grossVolume * 1000).toFixed(2);
      const netVolume = (singleItem.netVolume * 1000).toFixed(2);

      flattened.push({
        id,
        name: dataGroup.idMap[id]?.name,
        revenue,
        grossVolume,
        length: singleItem.length,
        netVolume,
        // Spread the remaining properties from singleItem here but exclude revenue, grossVolume, and netVolume
        ...omit(singleItem, ['revenue', 'grossVolume', 'netVolume']),
      });

      // Accumulate totals
      totalRevenue += singleItem.revenue || 0;
      totalGrossVolume += singleItem.grossVolume || 0;
      totalLength += singleItem.length || 0;
      totalNetVolume += singleItem.netVolume || 0;
    }
  }

  // Append totals row at the end
  flattened.push({
    id: 'Summary',
    name: 'Total',
    revenue: `$ ${totalRevenue.toFixed(2)}`,
    grossVolume: (totalGrossVolume * 1000).toFixed(2),
    length: totalLength,
    netVolume: (totalNetVolume * 1000).toFixed(2),
  });

  return flattened;
}

// Helper function to omit certain keys from an object
function omit(obj: any, keys: string[]): any {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result;
}
