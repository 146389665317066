import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import EllipsisMenu from "../menus/EllipsisMenu";
import { MenuOption } from "../types";

interface BackNavigableResourceHeaderProps {
  title: string;
  onBackClick: () => void;
  menuItems?: MenuOption[];
}

export default function BackNavigableResourceHeader(
  props: BackNavigableResourceHeaderProps
) {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-2">
        <ChevronLeftIcon
          className="w-6 stroke-slate-700 cursor-pointer"
          onClick={props.onBackClick}
        />
        <h1 className="text-2xl font-bold text-slate-700">{props.title}</h1>
      </div>
      {props.menuItems && <EllipsisMenu items={props.menuItems} />}
    </div>
  );
}
