// external imports
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// internal imports
import NewTally from './new/NewTally';
import Tally from './Tally';
import Stump from './stumps/Stump';
import NewStump from './stumps/new/NewStump';
import TalliesList from './TalliesList';

function Tallies() {
  useEffect(() => {
    document.title = 'Tallies | StumpTally';
  });
  // Tailwind sm breakpoint is 640px
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 641, maxWidth: 1024 });

  let display;

  if (isMobile) {
    display = (
      <Routes>
        <Route path="/" element={<TalliesList />} />
        <Route path="new" element={<NewTally />} />
        <Route path=":tallyId" element={<Tally />} />
        <Route path=":tallyId/stumps/new" element={<NewStump />} />
        <Route path=":tallyId/stumps/:stumpId" element={<Stump />} />
      </Routes>
    );
  } else if (isTablet) {
    display = (
      <div className="flex w-full overflow-x-hidden mr-1 overflow-y-hidden">
        <div className="w-1/2 mb-2">
          <Routes>
            <Route path="/" element={<TalliesList />} />
            <Route path=":tallyId" element={<TalliesList />} />
            <Route path=":tallyId/stumps/:stumpId" element={<Tally />} />
          </Routes>
        </div>
        <div className="w-1/2 mx-1 mb-2">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path="new" element={<NewTally />} />
            <Route path=":tallyId/*" element={<Tally />} />
            <Route path=":tallyId/stumps/new" element={<NewStump />} />
            <Route path=":tallyId/stumps/:stumpId" element={<Stump />} />
          </Routes>
        </div>
      </div>
    );
  } else {
    display = (
      <div className="flex w-full overflow-x-hidden mx-1 my-1">
        <div className="md:w-1/3 ">
          <TalliesList />
        </div>

        <div className="md:w-1/3 mx-1">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path="new" element={<NewTally />} />
            <Route path=":tallyId/*" element={<Tally />} />
          </Routes>
        </div>

        <div className="md:w-1/3">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path=":tallyId/stumps/new" element={<NewStump />} />
            <Route path=":tallyId/stumps/:stumpId" element={<Stump />} />
          </Routes>
        </div>
      </div>
    );
  }

  return display;
}

export default Tallies;
