import { useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase/firebase.config';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    signOut(auth)
      .then(() => {
        // Sign-out successful, redirect to login page
        navigate('/login');
      })
      .catch((error) => {
        // An error occurred
        console.error('Error signing out:', error);
      });
  }, [navigate]);

  return <p>Signing out...</p>; // you can customize this
}

export default Logout;
