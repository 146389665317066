import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';

type LengthPriceFieldProps = {
  field: {
    length: string | number;
    price: number | null;
  };
  lengthNumbers: Array<{ value: number; text: string }>;
  updateLength: (index: number, value: number) => void;
  updatePrice: (index: number, value: number) => void;
  removeField: (index: number) => void;
  index: number;
};

const LengthPriceField: React.FC<LengthPriceFieldProps> = ({
  field,
  lengthNumbers,
  updateLength,
  updatePrice,
  removeField,
  index,
}) => (
  <div key={index} className="flex w-full flex-col items-center">
    <div className="mb-4 w-full flex flex-row items-center">
      <Menu as="div" className="relative inline-block text-left ">
        <Menu.Button className="inline-flex justify-between w-28 font-medium  h-10 bg-slate-50 hover:text-slate-600 items-center rounded border border-slate-200 shadow px-2 py-2 text-sm text-slate-700 hover:bg-slate-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {field.length || 'Select'}
          <ChevronDownIcon
            className="ml-auto -mr-1 h-5 w-5 text-slate-700 hover:text-slate-600"
            aria-hidden="true"
          />
        </Menu.Button>
        <Menu.Items className="absolute max-h-80 overflow-auto z-50 w-28 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {lengthNumbers.map((number, idx) => (
            <Menu.Item key={idx}>
              {({ active }) => (
                <button
                  className={`text-lg h-12 ${
                    active ? 'bg-yellow-500 text-white' : 'text-gray-900'
                  } group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => updateLength(index, number.value)}
                >
                  {number.text}'
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
      <div className="relative flex flex-row items-center w-full mx-2">
        <span className="absolute left-3 text-slate-500">$</span>
        <label className="w-full">
          <input
            type="number"
            placeholder="0"
            className="h-10 rounded border border-slate-200 bg-slate-50 pl-6 shadow w-full"
            value={field.price || ''}
            onChange={(e) => updatePrice(index, parseFloat(e.target.value))}
          />
        </label>
      </div>
      <button
        type="button"
        className="border rounded-md bg-slate-50 flex items-center justify-center ml-auto p-1 hover:bg-slate-100"
        onClick={() => removeField(index)}
      >
        <TrashIcon className="h-8 w-8 p-1 text-slate-700" />
      </button>
    </div>
  </div>
);

export default LengthPriceField;
