import { XMarkIcon } from '@heroicons/react/24/outline';

interface ClosableHeaderProps {
  title: string;
  onCloseClick: () => void;
}

export default function ClosableHeader(props: ClosableHeaderProps) {
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <h1 className="text-2xl font-bold text-slate-700">{props.title}</h1>
      <button onClick={props.onCloseClick}>
        <XMarkIcon className="flex h-7 w-7 text-slate-700" />
      </button>
    </div>
  );
}
