import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

import { Fragment } from 'react';
import { MenuOption } from '../types';
import MenuItem from './MenuItem';

export default function EllipsisMenu({ items }: { items: MenuOption[] }) {
  return (
    <Menu as="div" className="relative inline-block text-left z-40">
      <div>
        <Menu.Button className=" w-full justify-center rounded  px-2 py-1 text-sm font-medium text-slate-700  sm:inline-flex">
          <EllipsisHorizontalIcon
            className=" h-5 w-5 text-black"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-1 w-56 origin-top-right rounded-md border border-slate-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
          {items.map((item: MenuOption) => (
            <div className="px-1 py-0" key={item.text}>
              <MenuItem item={item} />
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
