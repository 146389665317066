import { RadioGroup } from '@headlessui/react';
import { Controller } from 'react-hook-form';

type Option = {
  value: string;
  label: string;
};

interface CustomRadioGroupProps {
  name: string;
  control: any;
  defaultValue: string;
  label: string;
  options: Option[];
}

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  name,
  control,
  defaultValue,
  label,
  options,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup
          className=" mt-6 flex flex-col items-start"
          value={field.value}
          onChange={field.onChange}
        >
          <RadioGroup.Label className="label">{label}</RadioGroup.Label>
          <div className="mt-2 px-1 flex h-14 w-full flex-row items-center justify-center rounded-md border border-solid border-slate-200 bg-slate-50 ">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                className={`flex h-11 w-full cursor-pointer items-center justify-center rounded border-2 border-solid ${
                  field.value === option.value
                    ? 'border-yellow-500 bg-white'
                    : 'border-transparent bg-slate-50 '
                }`}
              >
                {({ checked }) => (
                  <span
                    className={
                      checked
                        ? 'font-semibold text-slate-700'
                        : 'font-normal text-slate-600'
                    }
                  >
                    {option.label}
                  </span>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      )}
    />
  );
};

export default CustomRadioGroup;
