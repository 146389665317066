// external imports
import { useState, useEffect, useContext } from 'react';
import {
  collection,
  query,
  onSnapshot,
  doc,
  where,
  getDocs,
  Timestamp,
} from 'firebase/firestore';
// internal imports
import { BookOpenIcon } from '@heroicons/react/24/outline';
import Cards from './Cards';
import { db } from '../../firebase/firebase.config';
import { AuthContext } from '../../AuthContext';
import { getDuration } from '../../utils/time';
import DeleteForm from '../../components/forms/DeleteForm';
import { Report } from '../../db/models';
import EditForm from '../../components/forms/EditForm';

function ReportsHome() {
  const { currentUser } = useContext(AuthContext);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Report>();
  const [reports, setReports] = useState<Report[]>([]);

  const handleDelete = (report: Report) => {
    setSelectedReport(report);
    setIsDeleteFormOpen(true);
  };

  const handleEdit = (report: Report) => {
    setSelectedReport(report);
    setIsEditFormOpen(true);
  };

  useEffect(() => {
    const fetchReports = async () => {
      const userId = currentUser?.uid;

      // Fetch reports for current user
      const reportsQuery = query(
        collection(db, 'reports'),
        where('userId', '==', userId)
      );

      onSnapshot(reportsQuery, async (snapshot) => {
        const reports = await Promise.all(
          snapshot.docs.map(async (doc) => {
            // const reportId = doc.id;
            const data = doc.data() as Report;

            return {
              id: data.id,
              name: data.name,
              tallyId: data.tallyId,
              productGroupId: data.productGroupId,
              userId: data.userId,
              createdAt: data.createdAt,
              updatedAt: data.updatedAt,
              status: data.status,
            };
          })
        );

        setReports(reports);
      });
    };

    fetchReports();
  }, []);

  console.log('reports:', reports);

  return (
    <>
      <div className="mt-4 lg:mt-8">
        <div className="flex items-center">
          <BookOpenIcon className="h-6 w-6 text-slate-500" />
          <h2 className="ml-2 text-slate-700 font-bold text-lg">Reports</h2>
        </div>
        <div className="lg:flex items-center lg:gap-x-4 lg:gap-y-4 lg:flex-wrap">
          {reports &&
            reports
              .sort((a, b) => b.updatedAt.seconds - a.updatedAt.seconds)
              .map((report) => (
                <span key={report.id}>
                  <Cards
                    name={report.name}
                    collectionName="reports"
                    id={report.id as string}
                    updatedAt={getDuration(report.updatedAt)}
                    onEdit={() => handleEdit(report)}
                    onDelete={() => handleDelete(report)}
                  />
                </span>
              ))}
        </div>
      </div>

      {selectedReport && isEditFormOpen && (
        <EditForm
          data={{ name: selectedReport.name }}
          id={selectedReport.id as string}
          collectionName="reports"
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
        />
      )}

      {selectedReport && isDeleteFormOpen && (
        <DeleteForm
          data={{ name: selectedReport.name }}
          id={selectedReport.id as string}
          collectionName="reports"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
        />
      )}
    </>
  );
}

export default ReportsHome;
