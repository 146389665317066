import React from 'react';

function Notes({ description }: { description: string | undefined }) {
  return (
    <div className="mt-6 text-left text-slate-700">
      <p className="text-slate-700">Notes: {description}</p>
    </div>
  );
}

export default Notes;
