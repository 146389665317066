import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { BarsArrowDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import MenuItem from './MenuItem';
import { MenuOption } from '../types';

type SortMenuProps = {
  selectedSort: string;
  onSelectSort: (sort: string) => void;
};

function SortMenu({ selectedSort, onSelectSort }: SortMenuProps) {
  const items = [
    {
      text: 'Common name',
      icon: CheckIcon,
    },
    {
      text: 'Genus',
      icon: CheckIcon,
    },
    {
      text: 'FIA species code',
      icon: CheckIcon,
    },
  ];
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className=" w-full justify-center rounded  px-2 py-1 text-sm font-medium text-slate-700  sm:inline-flex">
          <BarsArrowDownIcon
            className=" h-5 w-5 text-slate-700"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-1 w-56 origin-top-right rounded-md border border-slate-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
          {items.map((item) => (
            <div className="px-1 py-0" key={item.text}>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => onSelectSort(item.text)}
                    className={`${
                      active ? 'bg-slate-100' : 'bg-white'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm text-slate-700`}
                  >
                    <span className="w-5">
                      {item.text === selectedSort && (
                        <item.icon
                          className=" h-5 w-5 text-yellow-500"
                          aria-hidden="true"
                        />
                      )}
                    </span>

                    <span className="ml-2">{item.text}</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default SortMenu;
