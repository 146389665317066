import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import {
  PencilSquareIcon as PencilSquareSolidIcon,
  TrashIcon as TrashSolidIcon,
} from '@heroicons/react/24/solid';
import EllipsisMenu from '../../components/menus/EllipsisMenu';
import { MenuOption } from '../../components/types';
import { useNavigate } from 'react-router-dom';

interface CardsProps {
  name: string;
  collectionName: string;
  id: string;
  childNumber?: number | string | undefined;
  childWord?: string | undefined;
  updatedAt: string;
  onEdit: () => void;
  onDelete: () => void;
}

function Cards({
  name,
  collectionName,
  id,
  childNumber,
  childWord,
  updatedAt,
  onEdit,
  onDelete,
}: CardsProps) {
  const navigate = useNavigate();
  const items: MenuOption[] = [
    {
      text: 'Edit',
      Icon: PencilSquareIcon,
      IconHovered: PencilSquareSolidIcon,
      action: onEdit,
    },
    {
      text: 'Delete',
      Icon: TrashIcon,
      IconHovered: TrashSolidIcon,
      action: onDelete,
    },
  ];

  return (
    <div
      onClick={() => navigate(`${collectionName}/${id}`)}
      className="bg-white lg:hover:border-yellow-500 lg:transform lg:transition-transform lg:duration-300 lg:hover:scale-105 mt-4 lg:min-w-[300px] w-full p-4 border border-slate-200 self-stretch rounded-md flex cursor-pointer"
    >
      {/* left side */}
      <div className="flex-col w-full">
        <p className="text-slate-700 text-lg font-bold">{name}</p>
        <p className="text-slate-700 text-sm ">
          {childNumber} {childWord}
        </p>
        <p className="mt-6 text-slate-500 text-xs">Updated {updatedAt}</p>
      </div>
      {/* right side */}

      <span className="ml-auto">
        <span onClick={(event) => event.stopPropagation()}>
          <EllipsisMenu items={items} />
        </span>
      </span>
    </div>
  );
}

export default Cards;
