import { Transition } from '@headlessui/react';
import React, { Fragment, ReactNode } from 'react';

type PageWrapperProps = {
  children: ReactNode;
};

function PageWrapper({ children }: PageWrapperProps) {
  return (
    <Transition
      as={Fragment}
      show={true}
      appear={true}
      enter="ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="h-full max-h-[50px] min-h-full w-full m-1 lg:m-0  ">
        <div className=" flex h-full w-full flex-col overflow-y-auto rounded-lg border border-gray-300 bg-white p-4 ">
          {children}
        </div>
      </div>
    </Transition>
  );
}

export default PageWrapper;
