import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

import { Bars3Icon } from "@heroicons/react/24/outline";

import { NavigationOption } from "../types";
import ToolTip from "./ToolTip";

// NavButton props
interface NavButtonProps {
  selected: boolean;
  item: NavigationOption;
}

/**
 * A button for the side navigation menu.
 */
function NavButton({ selected, item }: NavButtonProps) {
  const navigate = useNavigate();

  return (
    <button
      className="flex w-full flex-row items-center gap-2 rounded p-2 hover:bg-slate-100"
      onClick={() => navigate(item.route)}
    >
      {selected ? (
        <item.IconHovered className="h-6 w-6 text-slate-700" />
      ) : (
        <item.Icon className="h-6 w-6 text-slate-600" />
      )}
    </button>
  );
}

/**
 * The side navigation component. This component can be toggled open and closed
 * by clicking the hamburger icon in the top left corner of the screen. It is only
 * visible on screen larger than 640px.
 */
export default function SideNav({
  topItems,
  bottomItems,
}: {
  topItems: NavigationOption[];
  bottomItems: NavigationOption[];
}) {
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[1] || "";

  return (
    // Side nav container
    <div className="z-10 hidden h-full  flex-col items-start justify-between border-r border-slate-200 bg-white px-2 pt-3 pb-4 sm:flex">
      {/* Top section */}
      {/* Top icon group: home, catalogs, tallies, and reports */}
      <div className="flex w-full flex-col items-center gap-3">
        {topItems.map((item) => (
          <ToolTip key={item.text} tooltip={item.text}>
            <NavButton
              selected={currentLocation === item.route.split("/")[1]}
              item={item}
            />
          </ToolTip>
        ))}
      </div>
      {/* Bottom section with settings and help buttons */}
      <div className="flex w-full flex-col items-center gap-3">
        {bottomItems.map((item) => (
          <ToolTip key={item.text} tooltip={item.text}>
            <NavButton
              selected={currentLocation === item.route.split("/")[1]}
              item={item}
            />
          </ToolTip>
        ))}
      </div>
    </div>
  );
}
